@font-face {
	font-family: 'Flama';
	src: url("../fonts/Flama-Light.eot");
	src: url("../fonts/Flama-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Flama-Light.woff2") format("woff2"), url("../fonts/univia-pro-book.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Flama';
	src: url("../fonts/Flama-Medium.eot");
	src: url("../fonts/Flama-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Flama-Medium.woff2") format("woff2"), url("../fonts/Flama-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Flama';
	src: url("../fonts/Flama-Bold.eot");
	src: url("../fonts/Flama-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Flama-Bold.woff2") format("woff2"), url("../fonts/Flama-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}


@font-face {
	font-family: 'Flama';
	src: url("../fonts/FlamaCondensed-Black.eot");
	src: url("../fonts/FlamaCondensed-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/FlamaCondensed-Black.woff2") format("woff2"), url("../fonts/FlamaCondensed-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
}

body {
	font-family: "Flama", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-size: 18px;
	font-weight: 300;
}
