.image-downloads {
    padding-top: 50px;
    padding-bottom: 50px;
    @include make-container(60px);
    @include make-container-max-widths();
    overflow: hidden;

    padding-left: 30px;
    padding-right: 30px;


    .pagination-holder {
        @include make-row(60px);
    }

    .items {
        //width: 100%;
        @include make-row(60px);

        a.item {
            @include make-col-ready(60px);

            @include media-breakpoint-up(sm) {
                @include make-col(1,2);
            }

            @include media-breakpoint-up(md) {
                @include make-col(1,3);
            }

            margin-bottom: 60px;
            color: #000;
            text-decoration: none;

            &:hover {
                color: #000;
                text-decoration: none;
            }

            .image {
                margin-bottom: 20px;

                img {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                }
            }

            .heading {
                @include ts-17-25-heading-a();
                @include header-accent($placement: below);
                padding-bottom: 8px;
                margin-bottom: 8px;
            }

            .download {
                @include ts-15-20();
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                padding-left: 20px;


                b {
                    font-weight: 600;
                }
            }
        }

        a.item:hover .download {
            color: #1dbfcd;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}
