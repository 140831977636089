.bh-navs .search {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 480px;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 9;
    pointer-events: all;

    input {
        width: 100%;
        height: 66px;
        padding: 0 10px 0 58px;
        border: 0;
        border-bottom: 10px solid #47BCCA;
        @include ts-22-30();
        line-height: 66px;
        background: #fff url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cg stroke='%23000' stroke-width='2'%3E%3Ccircle cx='7.667' cy='7.667' r='6.667' fill='none' /%3E%3Cpath d='M16.333 16.333L12.38 12.38' /%3E%3C/g%3E%3C/svg%3E") no-repeat 27px 50%;
        ;
    }
}


.search-results {
    @include make-container();
    @include make-container-max-widths();

    .search-info {
        @include make-row();
        margin-bottom: 40px;

        .richtext {
            @include make-col-ready();

            @include media-breakpoint-down(xs) {
                @include make-col(11);
            }

            @include media-breakpoint-up(sm) {
                @include make-col(7);
            }

            p+p {
                margin-top: 30px;
            }
        }
    }

    .items {
        @include make-row();

        .item {
            @include make-col-ready();

            @include media-breakpoint-down(xs) {
                @include make-col(11);
            }

            @include media-breakpoint-up(sm) {
                @include make-col(7);
            }

            margin-bottom: 30px;

            &:hover {
                color: #000;
                text-decoration: none;

                .heading {
                    color: #1dbfcd;
                }
            }

            .heading {
                padding-bottom: 12px;
                margin-bottom: 5px;

                @include ts-h4();
                @include header-accent();
            }

            .blurb {
                @include ts-17-25();
            }
        }
    }
}