.reports {
    padding-top: 50px;

    @include media-breakpoint-down(xs) {
        padding-top: 0;
    }

    padding-bottom: 50px;
    @include make-container(60px);
    @include make-container-max-widths();
    overflow: hidden;

    .pagination-holder {
        @include make-row(60px);
    }

    .items {
        @include make-row(60px);


        a.item {
            @include make-col-ready(60px);

            @include media-breakpoint-up(sm) {
                @include make-col(11,11);
            }

            padding: 30px;
            margin-bottom: 2px;
            color: #000;
            text-decoration: none;
            background-color: #F3F3F3;



            &:hover {
                color: #000;
                text-decoration: none;
            }

            .descriptor {
                @include ts-med();
            }

            .heading {


                @include media-breakpoint-up(md) {
                    max-width: 75%;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 50%;
                }

                @include ts-h3();
                padding-top: 8px;
                margin-top: 8px;
                margin-bottom: 10px;
                @include header-accent($placement: above);
            }

            .download {
                @include ts-15-20();
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                padding-left: 20px;


                b {
                    font-weight: 600;
                }
            }
        }

        a.item:hover .download {
            color: #1dbfcd;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}
