header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1049;

    @include media-breakpoint-up(sm) {
        right: 0;
        left: auto;
        width: 412px;
    }

    button:focus {
        outline: none;
    }

    .btns {
        z-index: 4;
        position: relative;
        display: flex;
        flex-flow: row;
        align-items: center;
        padding-right: 24px;
        padding-left: 40px;

        @include media-breakpoint-down(xs) {
            padding: 0 30px;
        }

        width: 100%;
        color: #fff;
        background-color: #000;

        .toplogo {
            text-decoration: none;
            padding: 37px 0;

            @media (max-height: 340px) {
                padding: 20px 0;
            }

            @include media-breakpoint-down(xs) {
                width: 84px;
            }

            margin-right: auto;

            &:focus {
                outline: none;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .lang {
            display: flex;
            flex-flow: column;

            a {
                width: 48px;
                font-size: 11px;
                color: #fff;
                text-align: center;
                text-decoration: none;

                &.active,
                &:hover {
                    color: #47BCCA;
                }
            }
        }

        .search-trigger {
            width: 38px;
            height: 38px;
            background: transparent;

            .closei {
                display: none;
            }

            &.open .closei {
                display: block;
            }

            .loupei {
                display: block;
            }

            &.open .loupei {
                display: none;
            }

            &:hover {
                .loupei,
                .closei {
                    stroke: #47BCCA;
                }
            }
        }

        .nav-trigger {
            width: 44px;
            height: 44px;
            border: 0;
            margin-right: -10px;
            background: transparent;

            #bmline1 {
                transform-origin: 2px 1px;
            }

            #bmline3 {
                transform-origin: 2px 15px;
            }

            polygon {
                transition: opacity 0.3s ease-in-out 0.2s, transform 0.4s ease-in-out 0s;
            }

            &.open {
                polygon {
                    transition: opacity 0.1s ease-in-out 0s, transform 0.4s ease-in-out 0s;
                }

                #bmline2 {
                    opacity: 0;
                }


                #bmline1 {
                    transform: rotate(45deg) translateX(1px);
                }

                #bmline3 {
                    transform: rotate(-45deg) translateX(1px);
                }
            }

            &:hover polygon {
                fill: #47BCCA;
            }
        }
    }

    &.home .btns::after {
        display: block;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 100%;
        height: 154px;
        //background: linear-gradient(180deg, rgba(0,0,0,255) 0%, rgba(0,0,0,0) 100%);
        transition: height 0.3s ease-in-out 0s;

        @at-root body.nav-opened #{&} {
            height: 0;
            transition: height 0.3s ease-in-out 0.3s;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

//IE HACK - IE11 doesn't support transforms on svg
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    #bmline1,
    #bmline3 {
        display: none;
    }

    header .btns .nav-trigger {
        position: relative;
    }

    header .btns .nav-trigger::before,
    header .btns .nav-trigger::after {
        display: block;
        position: absolute;
        content: '';
        width: 19px;
        height: 2px;
        left: 12px;
        background-color: #fff;
        transition: transform 0.4s ease-in-out 0s, width 0.4s ease-in-out 0s;
    }

    header .btns .nav-trigger::before {
        top: 14px;
        transform-origin: 2px 1px;
    }

    header .btns .nav-trigger::after {
        top: 28px;
        transform-origin: 2px 1px;
    }

    header .btns .nav-trigger.open::before {
        transform: rotate(45deg) translateX(1px);
        width: 22px;
    }
    header .btns .nav-trigger.open::after {
        transform: rotate(-45deg) translateX(1px);
        width: 22px;
    }
    header .btns .nav-trigger:hover::before,
    header .btns .nav-trigger:hover::after {
        background-color: #47BCCA;
    }
}


.topsearch {
    display: block;
    padding: 0;
    background-color: #000;
    position: relative;
    z-index: 2;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out 0s;

    &.open {
        display: block;
        max-height: 85px;
    }

    input {
        border: 0;
        padding: 0;
        width: calc(100% - 60px);
        font-size: 35px;
        font-weight: 300;
        letter-spacing: -.5px;
        line-height: 48px;
        background: #000;
        color: #fff;
        margin: 0 30px 40px 30px;

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #fff;
        }
    }
}


/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
