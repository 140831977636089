.btn-outline-light:not(:disabled):not(.disabled).active, 
.btn-outline-light:not(:disabled):not(.disabled):active {

}

.btn {
    padding: 16px;
    @include ts-button();
    border-radius: 0;
    width: 233px;
    max-width: 100%;

    &::before {
        position: relative;
        display: inline-block;
        content: '';
        width: 12px;
        height: 20px;
        margin-right: 5px;
        vertical-align: top;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 50% 50%;
    }

    &.btn-primary {
        border-color: #000;
        background-color: #000;
        color: #fff;

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:hover,
        &:active,
        &.active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:focus {
            border-color: #1dbfcd;
            background-color: #1dbfcd;
            color: #fff;
            outline: 0;
            box-shadow: none;
        }
    }

    &.btn-outline-primary {
        border-color: #000;
        background-color: transparent;
        color: #000;

        &:hover,
        &:active,
        &.active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:focus {
            border-color: #1dbfcd;
            background-color: transparent;
            color: #1dbfcd;
            outline: 0;
            box-shadow: none;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &.btn-outline-light {
        border-color: #fff;
        background-color: transparent;
        color: #fff;

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:hover,
        &:active,
        &.active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:focus {
            border-color: #1dbfcd;
            background-color: transparent;
            color: #1dbfcd;
            outline: 0;
            box-shadow: none;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
}
