.icon-title-block {
    margin-bottom: 20px;

    .header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        img {
            flex: 0 0 35px;
            height: 35px;
            margin: 3px 20px 0 0;

            @include media-breakpoint-down(xs) {
                display: none;
            }
        }

        h3 {
            @include ts-h3();
            @include header-accent($placement: below);
            padding-bottom: 10px;
            margin-bottom: 10px;
            margin-left: 55px;

            @include media-breakpoint-down(xs) {
                margin-left: 0;
            }
        }

        img + h3 {
            margin-left: 0;
        }
    }

    .richtext {
        margin: 0 0 0 55px;

        @include media-breakpoint-down(xs) {
            margin: 0;
        }
    }

    a.readmore {
        display: block;
        @include ts-med();
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        padding-left: 20px;
        text-decoration: none;

        &:hover {
            color: #1dbfcd;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}
