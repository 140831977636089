.combo-block.bigfeat {
    &.bi-block-orange {
        border-bottom: 15px solid #FAA02C;
        margin-bottom: 0 !important;
        margin-top: 15px !important;
    }

    &.bi-block-blue {
        border-bottom: 15px solid #1358A7;
        margin-bottom: 0 !important;
        margin-top: 15px !important;
    }

    &.bi-block-azure {
        border-bottom: 15px solid #47BCCA;
        margin-bottom: 0 !important;
        margin-top: 15px !important;
    }

    &.bi-block-purple {
        border-bottom: 15px solid #764FA1;
        margin-bottom: 0 !important;
        margin-top: 15px !important;
    }

    .pt-8 {
        padding-top: 8rem !important;
    }

    .bignumber {
        color: white;
    }

    a.ico-internal {
        color: #fff;
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        padding-left: 20px;
        text-decoration: none;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        padding-left: 20px;

        &:hover {
            color: #1dbfcd;
            text-decoration: none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    .heading-h3 {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 8px;
    }

    .primary .heading-h3 h3 {
        font-size: 50px;
        letter-spacing: -1px;
        line-height: 40px;

        @media (max-width: 768px) {
            font-size: 40px;
            line-height: 30px;
        }
    }

    .heading-h3::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 14px;
        height: 3px;
        background-color: #1dbfcd;
    }


}