$carouselItemWidth: calc((100% - 247px)/3);
$carouselItemWidthSmall: calc(100% / 3);

.carousel-slim.movingForth .item {
    left: 100%;
}

.carousel-slim.movingBack .item {
    left: calc(#{$carouselItemWidth} * -1);

    @include media-breakpoint-down(sm) {
        left: calc(#{$carouselItemWidthSmall} * -1);
    }
}

.carousel-slim .item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-clip: content-box;
    position: absolute;
    width: $carouselItemWidth;
    top: 0;
    bottom: 0;
    left: calc(#{$carouselItemWidth} * 4);
    opacity: 0;

    @include media-breakpoint-down(sm) {
        width: $carouselItemWidthSmall;
    }

    &.bigleftout, &.smallrightout, &.big1, &.big2, &.big3, &.small {
        opacity: 1;
    } 

    &.bigleftout {
        left: calc(#{$carouselItemWidth} * -1);

        @include media-breakpoint-down(sm) {
            left: calc(#{$carouselItemWidthSmall} * -1);
        }
    }

    &.big1 {
        left: 0;
    }

    &.big2 {
        left: $carouselItemWidth;

        @include media-breakpoint-down(sm) {
            left: $carouselItemWidthSmall;
        }
    }
    &.big3 {
        left: calc(#{$carouselItemWidth} * 2);

        @include media-breakpoint-down(sm) {
            left: calc(#{$carouselItemWidthSmall} * 2);
        }
    }

    &.small {
        left: calc(#{$carouselItemWidth} * 3);

        img {
            margin-left: 108px;
        }
        
        @include media-breakpoint-down(sm) {
            left: calc(#{$carouselItemWidthSmall} * 3);

            img {
                margin-left: 0;
            }
        }
    }

    &.smallrightout {
        left: calc(#{$carouselItemWidth} * 4);

        @include media-breakpoint-down(sm){
            left: calc(#{$carouselItemWidthSmall} * 4);
        }
    }

    &.big1,
    &.big2,
    &.big3,
    &.small {
        z-index: 3;
    }

    img {
        width: 100%;
        margin: auto;
        margin-left: 0;
        transition: margin-left 0.6s;
    }
}

@include media-breakpoint-down(sm) {
    .carousel-slim .btns {
        right: 0;
        top: calc( 50% - 23px);

        button {
            height: 45px;
            width: 40px;
        }
    }
}
