.videocard {
	margin-bottom: 20px;
	@include media-breakpoint-up(xl) { 
		max-width: 408px;

	}

	.media {
		position: relative;
		img {
			display: block;
			width: 100%;
			height: auto;
		}

		&::after {
			display: block;
			position: absolute;
			content: '';
			bottom: 0;
			width: 45px;
			height: 52px;
			background: #1dbfcd url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.895' height='17.965'%3E%3Cpath fill='%23FFF' d='M0 0v17.965l14.895-8.983z'/%3E%3C/svg%3E") no-repeat 50% 50%;
		}

		&:hover::after {
			background-color: #000;
		}
	}

	.title {
		margin-top: 20px;
		@include ts-17-25-heading-a();
	}

	.description {
		@include ts-17-25();
	}

	.btn {
		margin-top: 20px;
	}
}
