.row.grey {
    background-color: #f3f3f3;
    padding-left: 30px;
    padding-right: 30px;
}

span.dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 0;
    overflow: hidden;
    background-color: #000;

    &.orange {
        background-color: #FAA02C;
    }

    &.blue {
        background-color: #0559ac;
    }

    &.turquoise {
        background-color: #1dbfcd;
    }

    &.purple {
        background-color: #7b4ba4;
    }

    &.circle {
        background-color: transparent;
        border: 1px solid #000;

        &.orange {
            border-color: #FAA02C;
        }

        &.blue {
            border-color: #0559ac;
        }

        &.turquoise {
            border-color: #1dbfcd;
        }

        &.purple {
            border-color: #7b4ba4;
        }
    }
}


.bg-black {
    background-color: #000;
}
