.carousel {
    background-color: #000;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 46.707503%;

    .btns {
        position: absolute;
        top: calc( 50% - 35px);
        right: calc(24% - 53px);
        display: flex;
        flex-flow: row;
        z-index: 5;


        button {
            height: 73px;
            width: 53px;
            border: 0;
            transition: background-color 0.3s;

            &.prev {
                background: rgba(90, 188, 202, 0.7) url(../../static/img/ico-prev-white.svg) no-repeat 50% 50%;
            }

            &.next {
                background: rgba(90, 188, 202, 1) url(../../static/img/ico-next-white.svg) no-repeat 50% 50%;
            }

            &:hover {
                &.prev {
                    background-color: rgba(0, 0, 0, 0.7);
                }

                &.next {
                    background-color: rgba(0, 0, 0, 1);
                }
            }
        }
    }

    .cntnr {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 24%;
            bottom: 0;
            background-color: rgba(0,0,0,0.5);
            z-index: 4;
            pointer-events: none;
        }

        .item {
            overflow: hidden;
            position: absolute;
            left: -76%;
            top: 0;
            bottom: 0;
            width: 76%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                object-position: 0% 0%;
            }

            &.big {
                left: 0;
                width: 76%;
                z-index: 3;
            }

            &.small {
                left: 76%;
                width: 24%;
                z-index: 2;
            }
        }
    }
}


@include media-breakpoint-down(xs) {
    .carousel {
        padding-bottom: 61.491935%;
    }

    .carousel .cntnr .item.big {
        width: 100%;
    }

    .carousel .cntnr .item.small {
        width: 0;
    }

    .carousel .btns {
        position: absolute;
        right: 0;
        top: calc( 50% - 23px);

        button {
            height: 45px;
            width: 40px;
        }
    }

    .carousel .cntnr::before {
        display: none;
    }
}





.carousel .item.bigleftout {
    /* Previous big goes out to left */
    animation: 0.6s ease-in-out 0s both carouselanimbigleftout;
}

.carousel .item.big.small2big {
    /* Previous small goes to big */
    animation: 0.6s ease-in-out 0s both carouselanimsmalltobig;
}

.carousel .item.small.fromright {
    /* new small appears from right */
    animation: 0.6s ease-in-out 0s both carouselanimsmallfromright;
}


.carousel .item.big.fromleft {
    /* new big appears from left */
    animation: 0.6s ease-in-out 0s both carouselanimbigfromleft;
}

.carousel .item.small.big2small {
    /* Previous big goes to small */
    animation: 0.6s ease-in-out 0s both carouselanimbigtosmall;
}

.carousel .item.smallrightout {
    /* new big appears from left */
    animation: 0.6s ease-in-out 0s both carouselanimsmallrightout;
}




@keyframes carouselanimbigleftout {
    0% {
        width: 76%;
        left: 0;
    }

    100% {
        width: 76%;
        left: -76%;
    }
}

@include media-breakpoint-down(xs) {
    @keyframes carouselanimbigleftout {
        0% {
            width: 100%;
            left: 0;
        }

        100% {
            width: 100%;
            left: -100%;
        }
    }
}

@keyframes carouselanimsmalltobig {
    0% {
        width: 24%;
        left: 76%;
    }

    100% {
        width: 76%;
        left: 0;
    }
}

@include media-breakpoint-down(xs) {
    @keyframes carouselanimsmalltobig {
        0% {
            width: 100%;
            left: 100%;
        }

        100% {
            width: 100%;
            left: 0;
        }
    }
}

@keyframes carouselanimsmallfromright {
    0% {
        width: 24%;
        left: 100%;
    }

    100% {
        width: 24%;
        left: 76%;
    }
}

@include media-breakpoint-down(xs) {
    @keyframes carouselanimsmallfromright {
        0% {
            left: 100%;
        }

        100% {
            left: 100%;
        }
    }
}






@keyframes carouselanimbigfromleft {
    0% {
        width: 76%;
        left: -76%;
    }

    100% {
        width: 76%;
        left: 0;
    }
}

@include media-breakpoint-down(xs) {
    @keyframes carouselanimbigfromleft {
        0% {
            width: 100%;
            left: -100%;
        }

        100% {
            width: 100%;
            left: 0;
        }
    }
}

@keyframes carouselanimbigtosmall {
    0% {
        width: 76%;
        left: 0;
    }

    100% {
        width: 24%;
        left: 76%;
    }
}

@include media-breakpoint-down(xs) {
    @keyframes carouselanimbigtosmall {
        0% {
            width: 100%;
            left: 0;
        }

        100% {
            width: 100%;
            left: 100%;
        }
    }
}

@keyframes carouselanimsmallrightout {
    0% {
        width: 24%;
        left: 76%;
    }

    100% {
        width: 24%;
        left: 100%;
    }
}

@include media-breakpoint-down(xs) {
    @keyframes carouselanimsmallrightout {
        0% {
            left: 100%;
        }

        100% {
            left: 100%;
        }
    }
}
