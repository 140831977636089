.case-ar {
    padding: 75px 0 145px 0;
    color: #fff;
    background-color: #000;

    & > .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .sidetitle {
        color: #47BCCA;
    }

    .quote {
        padding: 70px 0 52px 0;

        .copy {
            font-size: 50px;
            letter-spacing: -1px;
            line-height: 40px;
            color: #fff;
            background-image: none;
        }
    }

    .richtext {
        color: #fff;
    }

    .large-image {
        img {
            display: block;
            width: 100%;
            height: auto;
            padding: 0 0 20px 0;
        }
    }

    .small-image {
        img {
            display: block;
            width: 100%;
            height: auto;
            padding: 0 0 20px 0;
        }

        .caption {
            width: 50%;
            margin-left: auto;
        }
    }
}





/* */
.fin-kpis {
    overflow: hidden;

    & > .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    &.grey {
        padding-top: 110px;
        padding-bottom: 110px;
        background-color: #F3F3F3;
    }

    .bignumber {
        .description.accent-under {
            padding-bottom: 10px;
        }
    }
}


.over-mosaic {
    @media (min-width: 1024px) {
        margin-bottom: -100px;
    }

    @media (min-width: 1199px) {
        margin-bottom: -150px;
    }
}





.download-block {
    padding: 25px 30px;
    background-color: #F3F3F3;

    .title {
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        position: relative;
        padding-bottom: 8px;
        margin-bottom: 8px;

        &::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 14px;
            height: 3px;
            background-color: #1dbfcd;
        }
    }

    .download {
        @include ts-15-20();
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        padding-left: 20px;


        b {
            font-weight: 600;
        }
    }

    &:hover {
        .download {
            color: #1dbfcd;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}



.chart-legend {
    width: 100%;
    display: block;
    margin: 20px 0;

    & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        justify-content: space-between;
        border-bottom: 1px solid #AFAFAF;
        padding: 7px 0;

        .color {
            width: 12px;
            height: 12px;
            position: relative;
            flex: 0 0 20px;
            width: 20px;

            &::after {
                display: block;
                position: absolute;
                top: 8px;
                width: 12px;
                height: 12px;
                content: "";
                background-color: currentColor;
               
            }
        }

        .label {
            flex: 1 1 auto;
        }

        .value {
            font-weight: 500;
        }
    }
}



.achievements {
    .item {
        margin-bottom: 20px;
        font-size:15px;
        p {
            margin: 0;
        }
    }
}


.statements {
    padding: 90px 0;
    background-color: #F3F3F3;

    & > .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}


.block-mosaic.reverse .m-r-title {
    align-self: flex-start;

    @media (min-width: 1024px) {
        position: absolute;
        max-width: 340px !important;
    }
}