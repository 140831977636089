main {
	width: 100%;
}

.container-fluid.full {
	padding-right: 0;
	padding-left: 0;
}

.sidetitle {
    color: #000;
    @include ts-h3();
    padding-right: 30px;
}

.verylargetitle {
    color: #000;
    font-size: 95px;
    font-weight: 900;
    letter-spacing: -2px;
    line-height: 73px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        font-size: 50px;
        line-height: 40px;
    }
}


main .preamble {
    color: #000;
    @include ts-22-30();
}
main b,
strong {
    font-weight: 500;
}

ul {
    list-style: square;
}
a {
    &:hover {
        color: #1dbfcd;
    }

    &.download,
    &.internal,
    &.external,
    &.external-rev {
        font-weight: 500;
        display: inline-block;
        text-decoration: none;


        &:hover {
            color: #1dbfcd;
            text-decoration: none;
        }
    }

    &.download {
        padding-left: 16px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &.internal {
        padding-left: 20px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &.external {
        padding-left: 16px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &.external-rev {
        padding-left: 16px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg transform='scale(-1,1) translate(-10,0)' fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg transform='scale(-1,1) translate(-10,0)' fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

