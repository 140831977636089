.pr-listing {
    @include make-container(60px);
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
}

.pr-item {
    @include make-row(60px);
    margin-bottom: 100px;
    color: #000;

    &:last-child {
        margin-bottom: 0;
    }

    .image,
    .header,
    .blurb {
        @include make-col-ready(60px);
    }

    .image {
        @include media-breakpoint-up(sm) {
            @include make-col(1,3);
        }

        @include media-breakpoint-up(md) {
            @include make-col(1,3);
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .header {
        @include media-breakpoint-up(sm) {
            @include make-col(2,3);
        }

        @include media-breakpoint-up(md) {
            @include make-col(1,3);
        }

        .date {
            @include ts-med();
        }

        h3 {
            @include ts-h3();
            text-transform: uppercase;
            padding-top: 8px;
            margin-top: 5px;
        }
    }

    .blurb {
        @include media-breakpoint-up(sm) {
            @include make-col(2,3);
            @include make-col-offset(1, 3);
        }

        @include media-breakpoint-up(md) {
            @include make-col(1,3);
            @include make-col-offset(0, 3);
        }

        .richtext {
        }

        .readmore {
            @include ts-med();
        }
    }
}

a.pr-item:hover {
    text-decoration: none;
    color: #000;
}

a.pr-item .blurb .readmore {
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
    padding-left: 20px;
}
a.pr-item:hover .blurb .readmore {
    text-decoration: underline;
    color: #1dbfcd;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
}


a.pr-compact-item {
    color: #000;
    text-decoration: none;

    &:hover {
        color: #1dbfcd;
        text-decoration: none;
    }
    .date {
        @include ts-15-20();
    }

    h3 {
        @include ts-h3();
    }
}




a.post-item {
    color: #000;
    text-decoration: none;

    &:hover {
        color: #1dbfcd;
        text-decoration: none;
    }

    .image {
        margin-bottom: 20px;

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }

    .description {
        @inclue ts-17-25();
    }
}