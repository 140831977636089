.jobs {
    padding-top: 50px;
    padding-bottom: 50px;
    @include make-container(60px);
    @include make-container-max-widths();
    overflow: hidden;

    .pagination-holder {
        @include make-row(60px);
    }

    .items {
        @include make-row(60px);


        .item {
            @include make-col-ready(60px);

            @include media-breakpoint-up(sm) {
                @include make-col(11,11);
            }

            padding: 35px 30px 30px 30px;
            margin-bottom: 2px;
            color: #000;
            text-decoration: none;
            background-color: #F3F3F3;


            .heading {


                @include media-breakpoint-up(md) {
                    max-width: 75%;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 50%;
                }

                @include ts-h3();
                padding-bottom: 14px;
                margin-bottom: 8px;
                @include header-accent($placement: below);
            }

            .details {
                @include ts-15-20();

                & > div + div {
                    margin-top: 3px;
                }
            }

            .btn {
                margin-top: 30px;
            }
        }
    }
}
