.performance-block {
  padding: 90px 0;
  color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  > .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .richtext,
  .preamble {
    color: #fff;
  }

  .richtext {
    margin-bottom: 90px;
  }

  h4 {
    padding-bottom: 14px;
    margin-bottom: -6px;
    @include header-accent($placement: below);
  }

  .bignumber {
    margin-bottom: 30px;

    .description {
      margin-top: -10px;
      font-weight: 500;
      max-width: none;
    }
  }

  .items .row .col {
    @media (max-width: 511.98px) {
      @include make-col(12, 12);
    }

    @media (max-width: 767.98px) and (min-width: 512px) {
      @include make-col(6, 12);
    }

    @include media-breakpoint-up(sm) {
      @include make-col(6, 12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(2, 11);
    }

    @include media-breakpoint-up(lg) {
    }
  }

  .items .row .col:nth-child(4n + 2),
  .items .row .col:nth-child(4n + 3),
  .items .row .col:nth-child(4n + 4) {
    @include media-breakpoint-up(md) {
      @include make-col-offset(1, 11);
    }
    // fix for numbers being too wide and causing columns to clash on certain resolutions
    @media (max-width: 1099.98px) and (min-width: 1024px) {
      margin-left: 7%;
    }
  }

  a.download {
    display: block;
    max-width: 405px;
    margin-top: 30px;
    color: #fff;
    padding-left: 0;
    background: none;

    .type {
      @include ts-descriptorle();
      padding-bottom: 8px;
      margin-bottom: 8px;
      @include header-accent($placement: below);
    }

    .title {
      @include ts-h4();
      text-transform: uppercase;
    }

    .pseudolink {
      @include ts-15-20();
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E")
        no-repeat 0 50%;
      padding-left: 20px;
      margin-top: 10px;

      b {
        font-weight: 600;
      }
    }

    &:hover {
      text-decoration: none;

      .title {
        color: #1dbfcd;
      }

      .pseudolink {
        color: #1dbfcd;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }
}

html.norwegian-site .performance-block {
  padding: 90px 0 0 0;
}
