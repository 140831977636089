.homehero .cntnr {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
    overflow: hidden;



    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 412px;
        height: 100vh;
        background-color: rgba(0,0,0,0.35);
        z-index: 4;
        pointer-events: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}
