.homehero .item {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-clip: content-box;
    position: absolute;
    right: 100%;
    width: 100%;
    border-right: 412px solid transparent;
    top: 0;
    bottom: 0;

    &.big {
        right: 0;
        width: 100%;
        z-index: 3;
        background-position: 50% 50%;

        @include media-breakpoint-down(sm) {
            background-position: 50% 50%;
        }
    }

    &.small {
        right: 0;
        width: 412px;
        border-right-width: 0;
        background-position: 50% 50%;
    }

    &.big,
    &.bigleftout,
    &.big2small,
    &.fromleft,
    &.small2big {
        z-index: 3;
    }


    .copy {
        position: absolute;
        margin-left: 60px;
        z-index: 5;

        .header {
            color: #fff;
            text-transform: uppercase;
        }

        .preamble {
            margin-top: 20px;
            height: auto;
            width: 402px;
            color: #fff;
            font-size: 22px;
            font-weight: 300;
            line-height: 30px;

            & > p {
                @include media-breakpoint-down(xs) {
                    display: none;
                }
            }

            .btn {
                margin-top: 16px;
            }
        }
    }

    &.big .copy,
    &.bigleftout .copy,
    &.big2small .copy,
    &.fromleft .copy {
        top: calc(50% - 92px);

        @include media-breakpoint-down(sm) {
            top: calc(50% - 52px);
        }

        @include media-breakpoint-down(xs) {
            top: auto;
            bottom: 30px;
            margin-left: 30px;
        }

        @include media-breakpoint-only(md) {
            top: calc(50% - 70px);
        }

        .header {
            width: calc(100% - 126px);
            max-width: 622px;
            font-size: 115px;
            font-weight: 900;
            letter-spacing: -3px;
            line-height: 90px;
            text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

            @media (max-height: 1000px) and (min-width: 1200px) {
                font-size: 88px;
                letter-spacing: -1.29px;
                line-height: 70px;
            }

            @media (max-height: 750px) and (min-width: 1200px) {
                font-size: 70px;
                letter-spacing: -1.29px;
                line-height: 56px;
            }

            @media (max-height: 560px) and (min-width: 1200px) {
                font-size: 60px;
                letter-spacing: -1.29px;
                line-height: 50px;
            }

            @include media-breakpoint-only(md) {
                font-size: 88px;
                letter-spacing: -1.29px;
                line-height: 70px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 60px;
                letter-spacing: -1.29px;
                line-height: 50px;
            }
        }
    }








    &.super.big .copy,
    &.super.bigleftout .copy,
    &.super.big2small .copy,
    &.super.fromleft .copy {
        top: calc(50% - 128px);

        @media (min-width: 1200px) and (max-width: 1474px) {
            top: calc(50% - 95px);
        }

        @include media-breakpoint-down(sm) {
            top: calc(50% - 52px);
        }

        @include media-breakpoint-down(xs) {
            top: auto;
            bottom: 30px;
            margin-left: 30px;
        }

        @include media-breakpoint-only(md) {
            top: calc(50% - 70px);
        }

        .header {
            width: calc(100% - 126px);
            max-width: 1033px;
            font-size: 165px;
            letter-spacing: -3px;
            line-height: 0.76;
            font-weight: 900;
            text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

            @media (max-height: 1000px) and (min-width: 1200px) and (max-width: 1474px) {
                font-size: 118px;
                letter-spacing: -3px;
                line-height: 0.76;
            }

            @media (max-height: 750px) and (min-width: 1200px) and (max-width: 1474px) {
                font-size: 118px;
                letter-spacing: -3px;
                line-height: 0.76;
            }

            @media (max-height: 560px) and (min-width: 1200px) and (max-width: 1474px) {
                font-size: 118px;
                letter-spacing: -3px;
                line-height: 0.76;
            }






            @media (max-height: 1000px) and (min-width: 1475px) {
                font-size: 165px;
                letter-spacing: -3px;
                line-height: 0.76;
            }

            @media (max-height: 750px) and (min-width: 1475px) {
                font-size: 165px;
                letter-spacing: -3px;
                line-height: 0.76;
            }

            @media (max-height: 560px) and (min-width: 1475px) {
                font-size: 165px;
                letter-spacing: -3px;
                line-height: 0.76;
            }


            @include media-breakpoint-only(md) {
                font-size: 88px;
                letter-spacing: -1.29px;
                line-height: 70px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 60px;
                letter-spacing: -1.29px;
                line-height: 50px;
            }
        }
    }










    &.fromleft .copy .header,
    &.fromleft .copy .preamble {
        transform: translateY(65px);
        opacity: 0;
    }

    &.small .copy,
    &.smallrightout .copy,
    &.small2big .copy,
    &.fromright .copy {
        top: 50%;
        transform: translateY(-50%);

        .header {
            padding-left: 17px;
            width: 74%;
            max-width: 260px;
            font-size: 42px;
            font-weight: 900;
            letter-spacing: -0.5px;
            line-height: 35px;
        }

        .preamble {
            overflow: hidden;
            height: 0;
            margin-top: 0;
        }
    }

    &.fromright .copy .header {
        transform: translateY(45px);
        opacity: 0;
    }
}


@include media-breakpoint-down(sm) {
    .homehero .btns {
        right: 0;
        top: calc( 50% - 23px);

        button {
            height: 45px;
            width: 40px;
        }
    }

    .homehero .cntnr::before {
        display: none;
    }

    .homehero .item.small {
        right: -412px;
    }

    .homehero .item.big {
        border-right-width: 0;
    }
}






.homehero .item.bigleftout {
    /* Previous big goes out to left */
    animation: 0.6s ease-in-out 0.3s both animbigleftout;
}

.homehero .item.small2big {
    /* Previous small goes to big */
    animation: 0.6s ease-in-out 0.3s both animsmalltobig;
}

.homehero .item.fromright {
    /* new small appears from right */
    animation: 0.6s ease-in-out 0.3s both animsmallfromright;
}

.homehero .item.fromleft {
    /* new big appears from left */
    animation: 0.6s ease-in-out 0.3s both animbigfromleft;
}

.homehero .item.big2small {
    /* Previous big goes to small */
    animation: 0.6s ease-in-out 0.3s both animbigtosmall;
}

.homehero .item.smallrightout {
    /* new big appears from left */
    animation: 0.6s ease-in-out 0.3s both animsmallrightout;
}

//SLIDES ANIMATIONS

@keyframes animsmalltobig {
    0% {
        right: 0;
        width: 412px;
        border-right-width: 0;
        background-position: 50% 50%;
    }

    100% {
        right: 0;
        width: 100%;
        border-right-width: 412px;
        background-position: 50% 50%;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes animsmalltobig {
        0% {
            right: -412px;
            width: 412px;
            border-right-width: 0;
            background-position: 50% 50%;
        }

        100% {
            right: 0;
            width: 100%;
            border-right-width: 0;
            background-position: 50% 50%;
        }
    }
}

@keyframes animbigtosmall {
    0% {
        right: 0;
        width: 100%;
        border-right-width: 412px;
        background-position: 50% 50%;
    }

    100% {
        right: 0;
        width: 412px;
        border-right-width: 0;
        background-position: 50% 50%;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes animbigtosmall {
        0% {
            right: 0;
            width: 100%;
            border-right-width: 0;
            background-position: 50% 50%;
        }

        100% {
            right: -412px;
            width: 412px;
            border-right-width: 0;
            background-position: 50% 50%;
        }
    }
}

@keyframes animsmallfromright {
    0% {
        right: -412px;
        width: 412px;
        border-right-width: 0;
        background-position: 50% 50%;
    }

    100% {
        right: 0;
        width: 412px;
        border-right-width: 0;
        background-position: 50% 50%;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes animsmallfromright {
        0% {
            right: -412px;
            width: 412px;
            border-right-width: 0;
            background-position: 50% 50%;
        }

        100% {
            right: -412px;
            width: 412px;
            border-right-width: 0;
            background-position: 50% 50%;
        }
    }
}

@keyframes animbigfromleft {
    0% {
        right: 100%;
        width: 100%;
        border-right-width: 0;
        background-position: 50% 50%;
    }

    100% {
        right: 0;
        width: 100%;
        border-right-width: 412px;
        background-position: 50% 50%;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes animbigfromleft {
        0% {
            right: 100%;
            width: 100%;
            border-right-width: 0;
            background-position: 50% 50%;
        }

        100% {
            right: 0;
            width: 100%;
            border-right-width: 0;
            background-position: 50% 50%;
        }
    }
}

@keyframes animsmallrightout {
    0% {
        right: 0;
        width: 412px;
        border-right-width: 0;
        background-position: 50% 50%;
    }

    100% {
        right: -412px;
        width: 412px;
        border-right-width: 0;
        background-position: 50% 50%;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes animsmallrightout {
        0% {
            right: -412px;
            width: 412px;
            border-right-width: 0;
            background-position: 50% 50%;
        }

        100% {
            right: -412px;
            width: 412px;
            border-right-width: 0;
            background-position: 50% 50%;
        }
    }
}

@keyframes animbigleftout {
    0% {
        right: 0;
        width: 100%;
        border-right-width: 412px;
        background-position: 50% 50%;
    }

    100% {
        right: 100%;
        width: 100%;
        border-right-width: 0;
        background-position: 50% 50%;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes animbigleftout {
        0% {
            right: 0;
            width: 100%;
            border-right-width: 0;
            background-position: 50% 50%;
        }

        100% {
            right: 100%;
            width: 100%;
            border-right-width: 0;
            background-position: 50% 50%;
        }
    }
}


//COPY ANIMATIONS
@keyframes anim-bigheader-in {
    0% {
        padding-left: 0;
        transform: translateY(65px);
        opacity: 0;
    }

    100% {
        padding-left: 0;
        transform: translateY(0);
        opacity: 1;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes anim-bigheader-in {
        0% {
            padding-left: 0;
            transform: translateY(65px);
            opacity: 0;
        }

        100% {
            padding-left: 0;
            transform: translateY(0);
            opacity: 1;
        }
    }
}


@keyframes anim-bigheader-out {
    0% {
        padding-left: 0;
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        padding-left: 0;
        transform: translateY(65px);
        opacity: 0;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes anim-bigheader-out {
        0% {
            padding-left: 0;
            transform: translateY(0);
            opacity: 1;
        }

        100% {
            padding-left: 0;
            transform: translateY(65px);
            opacity: 0;
        }
    }
}










@keyframes anim-smallheader-in {
    0% {
        padding-left: 17px;
        transform: translateY(45px);
        opacity: 0;
    }

    100% {
        padding-left: 17px;
        transform: translateY(0);
        opacity: 1;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes anim-smallheader-in {
        0% {
            padding-left: 17px;
            transform: translateY(45px);
            opacity: 0;
        }

        100% {
            padding-left: 17px;
            transform: translateY(0);
            opacity: 1;
        }
    }
}


@keyframes anim-smallheader-out {
    0% {
        padding-left: 17px;
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        padding-left: 17px;
        transform: translateY(45px);
        opacity: 0;
    }
}

@include media-breakpoint-down(sm) {
    @keyframes anim-smallheader-out {
        0% {
            padding-left: 17px;
            transform: translateY(0);
            opacity: 1;
        }

        100% {
            padding-left: 17px;
            transform: translateY(45px);
            opacity: 0;
        }
    }
}





.homehero .item.bigleftout .copy .header {
    /* Previous big goes out to left */
    animation: 0.3s ease-in-out 0s both anim-bigheader-out;
}

.homehero .item.small2big .copy .header {
    /* Previous small goes to big */
    animation: 0.3s ease-in-out 0s both anim-smallheader-out;
}

.homehero .item.fromright .copy .header {
    /* new small appears from right */
    //animation: 0.3s ease-in-out 0s both animsmallfromright-header;
}

.homehero .item.fromleft .copy .header {
    /* new big appears from left */
    // animation: 0.3s ease-in-out 0s both animbigfromleft-header;
}

.homehero .item.big2small .copy .header {
    /* Previous big goes to small */
    animation: 0.3s ease-in-out 0s both anim-bigheader-out;
}

.homehero .item.smallrightout .copy .header {
    /* new big appears from left */
    animation: 0.3s ease-in-out 0s both anim-smallheader-out;
}

.homehero .item.small .copy .header {
    /* Small initial */
    animation: 0.3s ease-in-out 0.2s both anim-smallheader-in;
}
.homehero .item.big .copy .header {
    /* Small initial */
    animation: 0.3s ease-in-out 0.2s both anim-bigheader-in;
}


.homehero .item.bigleftout .copy .preamble {
    /* Previous big goes out to left */
    animation: 0.3s ease-in-out 0.2s both anim-bigheader-out;
}
.homehero .item.big2small .copy .preamble {
    /* Previous big goes out to left */
    animation: 0.3s ease-in-out 0.2s both anim-bigheader-out;
}
.homehero .item.big .copy .preamble {
    /* Small initial */
    animation: 0.3s ease-in-out 0s both anim-bigheader-in;
}