.twitter-card {
    display: flex;
    flex-direction: column;

    .heading {
        flex: 0 0 auto;
        padding: 30px 30px 10px 30px;

        .logo {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-bottom: 10px;
            overflow: hidden;

            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }

        .byline {
            display: block;
            @include ts-15-20();
            color: #000;

            .strong {
                font-weight: 600;
            }

            time {
            }

            :hover {
                color: #47BCCA;
            }
        }
    }

    .content {
        flex: 1 0 auto;
        padding: 0 30px 30px 30px;
        @include ts-15-25-social-body();
        color: #000;
    }

    .foot {
        padding: 30px;
        flex: 0 0 auto;
    }
}
