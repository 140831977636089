.carousel-slim .btns {
    position: absolute;
    top: calc( 50% - 35px);
    right: 209px;
    display: flex;
    flex-flow: row;
    z-index: 5;

    @include media-breakpoint-down(sm) {
        right: 0;
        top: calc( 50% - 23px);
    }

    button {
        height: 53px;
        width: 38px;
        border: 0;
        transition: background-color 0.3s;

        @include media-breakpoint-down(sm) {
            height: 45px;
            width: 40px;
        }

        &.prev {
            background: rgba(90, 188, 202,0.7) url(../../static/img/ico-prev-white.svg) no-repeat 50% 50%;
        }

        &.next {
            background: rgba(90, 188, 202,1) url(../../static/img/ico-next-white.svg) no-repeat 50% 50%;
        }

        &:hover {
            &.prev {
                background-color: rgba(0, 0, 0, 0.7);
            }

            &.next {
                background-color: rgba(0, 0, 0, 1);
            }
        }

        &:focus {
            outline: none;
        }
    }
}
