.bh-navs {
    position: relative;
    max-width: 1320px;
    margin: 0 auto;
    text-align: center;
    min-height: 74px;
    pointer-events: none;
}

.bh-download {
    display: block;
    width: 233px;
    position: absolute;
    top: 0;
    left: 30px;
    padding: 0;
    margin: 0;
    padding: 17px 10px;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    text-transform: uppercase;
    background: #000;
    //
    z-index: 9;
    pointer-events: all;
    transform: translateY(-50%);

    b {
        font-weight: 500;
    }

    span {
        display: inline-block;
        margin-right: 5px;
        width: 10px;
        min-height: 15px;
        vertical-align: bottom;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
    }

    @include media-breakpoint-down(sm) {
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        text-decoration: none;
        color: #1dbfcd;

        span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}

.bh-dark-nav {
    width: 233px;
    position: absolute;
    top: 0;
    right: 30px;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 9;
    pointer-events: all;
    transform: translateY(-50%);

    @include media-breakpoint-down(sm) {
        left: 50%;
        transform: translate(-50%, -50%);
    }

    li {
        padding: 0;
        border-top: 1px solid transparent;
        margin: 0;
        background-color: #000;
    }

    li + li {
        border-top: 1px solid #fff;
    }

    a {
        display: block;
        padding: 17px 10px;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;
        text-align: center;
    }

    a:hover {
        text-decoration: none;
        color: #47BCCA;
    }

    li.ico-arrow,
    li.ico-chat {
        a::before {
            position: relative;
            content: '';
            display: inline-block;
            width: 12px;
            height: 20px;
            margin-right: 5px;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: 100% 50%;
            vertical-align: bottom;
        }
    }

    li.ico-arrow a::before,
    li.ico-chat a::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/svg%3E");
    }

    li.ico-arrow a:hover::before,
    li.ico-chat a:hover::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cpath fill='none' stroke='%2347BCCA' stroke-linecap='round' stroke-linejoin='round' d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/svg%3E");
    }
}