.pagination {
    display: block;
    margin: 20px 0;
    text-align: center;

    a {
        display: inline-block;
        padding: 4px 10px;
        @include ts-med();
        color: #000;
        min-width: 14px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: #1dbfcd;
        }

        &.active {
            color: #000;
            border-bottom: 3px solid #1dbfcd;
        }

        &.prev,
        &.next {

            @include text-hide;
            padding: 4px 20px;
            vertical-align: middle;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            height: 20px;
        }

        &.prev {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cg fill-rule='evenodd' fill='%23000'%3E%3Cpath d='M12.247 1.32L11.684.76.872 11.697l.562.562z'/%3E%3Cpath d='M.49 12.062l.562-.562 10.753 10.876-.563.562z'/%3E%3C/g%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cg fill-rule='evenodd' fill='%231dbfcd'%3E%3Cpath d='M12.247 1.32L11.684.76.872 11.697l.562.562z'/%3E%3Cpath d='M.49 12.062l.562-.562 10.753 10.876-.563.562z'/%3E%3C/g%3E%3C/svg%3E");
            }
        }

        &.next {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cg fill-rule='evenodd' fill='%23000'%3E%3Cpath d='M.753 1.32L1.316.76l10.812 10.938-.562.562z'/%3E%3Cpath d='M12.51 12.062l-.562-.562L1.195 22.376l.563.562z'/%3E%3C/g%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cg fill-rule='evenodd' fill='%231dbfcd'%3E%3Cpath d='M.753 1.32L1.316.76l10.812 10.938-.562.562z'/%3E%3Cpath d='M12.51 12.062l-.562-.562L1.195 22.376l.563.562z'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
}
