nav:not(.ar-nav) {
    position: fixed;
    top: -100vh;
    right: 0;
    height: 100vh;
    z-index: 1048;

    @include media-breakpoint-down(xs) {
        width: 100%;
    }

    background-color: #000;
    z-index: 2;

    &:not(.contracting) {
        transition: top 0.3s ease-in-out;
    }

    &.contracting {
        transition: top 0.3s ease-in-out 0.3s !important;
    }

    &.open {
        top: 0.001vh;
        transition: top 0.3s ease-in-out;
    }

    ul {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(xs) {
            justify-content: flex-start;
            padding-top: 178px;
        }

        right: 0;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
            width: 412px;
        }

        height: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        background-color: rgba(0,0,0,0.8);
        z-index: 2;
        transition: right 0.3s ease-in-out;
        padding-top: 111px !important;

        @media (max-height: 340px) {
            padding-top: 68px !important;
        }
    }

    &.expanded ul {
        @include media-breakpoint-down(sm) {
            right: 100%;
        }

        @include media-breakpoint-up(sm) {
            right: 412px;
        }
    }

    ul ul {
        display: none;
        z-index: 1;
        position: absolute;
        top: 0;
        overflow-y: auto;

        @include media-breakpoint-down(sm) {
            left: 100%;
        }

        @include media-breakpoint-up(sm) {
            left: 412px;
        }
    }

    ul .expanded ul {
        display: flex;
    }

    li {
        width: 100%;
        padding: 0 56px;

        @include media-breakpoint-down(xs) {
            padding: 0 30px;
        }
    }

    a {
        display: block;
        width: 100%;
        text-align: right;
        padding: 9px 0;

        @media (max-height: 340px) {
            padding: 5px 0;
        }

        font-family: "Flama";
        @include ts-main-navigation();
        text-align: right;
        color: #fff;
        transition: color 0.3s;
    }

    a:hover,
    .expanded > a,
    .active > a {
        text-decoration: none;
        color: #1dbfcd;
    }


    .landing > a,
    .back > a {
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            padding: 23px 0 7px 0;
            @include ts-med();
        }
    }

    .landing > a {
        &::before {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 1em;
            content: '';
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23fff'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 5px;

            @include media-breakpoint-down(xs) {
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%23FFF' d='M12.511 12.172L1.758 23.048l-.563-.562 10.187-10.303L.753 1.431 1.316.87l11.195 11.302z'/%3E%3C/svg%3E") no-repeat 0 11px;
            }
        }

        &:hover::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%231dbfcd'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");

            @include media-breakpoint-down(xs) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%231dbfcd' d='M12.511 12.172L1.758 23.048l-.563-.562 10.187-10.303L.753 1.431 1.316.87l11.195 11.302z'/%3E%3C/svg%3E");
            }
        }
    }


    .back > a {
        @include media-breakpoint-up(md) {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            padding-bottom: 34px;
            text-align: left;
            @include ts-med();
        }


        &::before {
            position: relative;
            display: inline-block;
            width: 15px;
            height: 11px;
            content: '';
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%23FFF' d='M12.753 22.486L2.125 11.737 12.312 1.431 11.749.87 1.021 11.723l11.17 11.325z'/%3E%3C/svg%3E") no-repeat 0 -6px;
        }

        &:hover::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%231dbfcd' d='M12.753 22.486L2.125 11.737 12.312 1.431 11.749.87 1.021 11.723l11.17 11.325z'/%3E%3C/svg%3E");
        }
    }
}
