table.table {
    margin: 20px 0;
    @include ts-17-25();

    th {
        @include ts-med();
    }

    thead th {
        border: 0;
    }

    tbody tr:nth-child(odd) {
        background-color: #ecfafa;
    }

    td {
        border: 0;

        a.download {
            display: block;
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
            padding-left: 16px;
            color: #000;

            b {
                @include ts-med();
            }
        }
    }

    caption {
        margin: 12px 0 0 0;
        padding: 0;
        font-size: 15px;
    }
}

table.table.ar {
    tbody tr:nth-child(odd) {
        background-color: transparent;
    }
    border-bottom: 2px solid #47BCCA;

    thead {
        border-bottom: 1px solid #47BCCA;
    }

    tr.underlined {
        border-bottom: 1px solid #47BCCA;
    }

    th:not(:first-child),
    td:not(:first-child) {
        text-align: right;
    }

    th.bg,
    td.bg {
        background-color: rgba(71, 188, 202, 0.1) !important;
    }

    .indent-left-1 {
    	padding-left: 2rem;
    }
    .indent-left-2 {
    	padding-left: 4rem;
    }
    .indent-left-3 {
    	padding-left: 6rem;
    }
    .indent-left-4 {
    	padding-left: 8rem;
    }
    .indent-left-5 {
    	padding-left: 10rem;
    }
}

.twrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;

    background:
    /* Shadow covers */
    linear-gradient(90deg, #fff 30%, rgba(255,255,255,0)), linear-gradient(90deg, rgba(255,255,255,0), #fff 70%) 100% 100%,
    /* Shadows */
    radial-gradient(farthest-side at 0 50%, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.2), rgba(0,0,0,0)) 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-attachment: local, local, scroll, scroll;
}





