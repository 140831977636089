.country-feat-block {
    @include make-container();

    .items {
        @include make-row();

        @include media-breakpoint-up(md) {
            flex-flow: row nowrap;
        }
    }

    .item {
        height: 360px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        @include make-col-ready();

        @include media-breakpoint-down(xs) {
            @include make-col(1, 1);
        }

        @include media-breakpoint-only(sm) {
            @include make-col(2, 4);
        }

        @include media-breakpoint-up(md) {
            //@include make-col(1, 4);
            min-width: 25%;
        }


        &.tint-white::before,
        &.tint-black::before {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        &.tint-white::before {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 100%);
        }

        &.tint-black::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
        }

        &.tint-black .copy {
            // text-shadow: 0 2px 4px rgba(0,0,0,0.15);
        }

        .copy {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 0 30px 30px;
            height: auto;
            z-index: 2;

            .header {
                color: #fff;
                @include ts-h3();
                padding-bottom: 75px;
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 34 34'%3E%3Cg fill='none' stroke='%23FFF' stroke-width='2'%3E%3Ccircle cx='17' cy='17' r='16'/%3E%3Cpath d='M24 17.5H11m6.5-6.5v13'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 100%;

                @media (max-width: 1300px) and (min-width: 1024px) {
                    font-size: 40px;
                    line-height: 33px;
                }
            }
        }

        &.dark .copy {
            .header {
                color: #000;
            }
        }

        &:hover .copy {
            .header {
                color: #1dbfcd;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 34 34'%3E%3Cg fill='none' stroke='%2347BCCA' stroke-width='2'%3E%3Ccircle cx='17' cy='17' r='16'/%3E%3Cpath d='M24 17.5H11m6.5-6.5v13'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
}