.latest-pr {
    overflow: auto;
    padding-top: 60px;
    padding-bottom: 60px;


    .header {
        @include make-container-max-widths();
        margin-right: auto;
        margin-left: auto;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 40px;

        h4.small {
            font-weight: 700;
        }
    }

    .wrapper {
        @include make-container(60px);
        @include make-container-max-widths();
        overflow: hidden;

        .items {
            @include make-row(60px);

            .pr-compact-item {
                @include make-col-ready(60px);

                @include media-breakpoint-down(xs) {
                    @include make-col(11,11);
                }

                @include media-breakpoint-up(sm) {
                    @include make-col(1,3);
                }
            }

            .pr-compact-item + .pr-compact-item {
                @include media-breakpoint-down(xs) {
                    margin-top: 30px;
                }
            }
        }
    }
}
