body>footer,
body>#recite-wrapper>footer {
    color: #fff;
    background-color: #000;
    font-size: 17px;
    line-height: 25px;

    @include media-breakpoint-down(xs) {
        font-size: 15px;
        line-height: 16px;
    }
}

body>footer .wrapper,
body>#recite-wrapper>footer .wrapper {
    @include media-breakpoint-up(xs) {
        max-width: 100%;
        padding: 30px;
    }

    @include media-breakpoint-up(sm) {
        max-width: map-get($container-max-widths, 'sm');
        padding: 155px 30px 30px 30px;
    }

    @include media-breakpoint-up(md) {
        max-width: map-get($container-max-widths, 'md');
    }

    @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, 'lg');
    }

    @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, 'xl');
    }

    position: relative;
    margin: 0 auto;
    display: grid;

    @include media-breakpoint-up(sm) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto;
        grid-template-areas: "primarylinks social""legal secondarylinks";
        grid-row-gap: 40px;
    }

    @include media-breakpoint-down(xs) {
        grid-template-columns: 40% 60%;
        grid-template-rows: auto auto auto;
        grid-template-areas: "social social""primarylinks secondarylinks""legal legal";
        grid-row-gap: 40px;
    }
}

body>footer a,
body>#recite-wrapper>footer a {
    color: #fff;
}

body>footer a:hover,
body>#recite-wrapper>footer a:hover {
    color: #47bcca;
    text-decoration: none;
}

body>footer .shortcuts.primary,
body>#recite-wrapper>footer .shortcuts.primary {
    @include media-breakpoint-down(xs) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
    }

    @include media-breakpoint-up(sm) {
        max-width: 330px;
        column-count: 2;
        column-gap: 20px;
        margin-top: 5px;
    }
}

body>footer .shortcuts.primary a,
body>#recite-wrapper>footer .shortcuts.primary a {
    display: block;

    @include media-breakpoint-down(xs) {
        font-size: 16px;
        line-height: 20px;
        padding: 5px 5px 5px 0;
    }

    @include media-breakpoint-up(sm) {
        margin-bottom: 20px;
    }
}

body>footer .shortcuts.secondary,
body>#recite-wrapper>footer .shortcuts.secondary {
    @include media-breakpoint-down(xs) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
    }

    @include media-breakpoint-up(sm) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
        display: flex;
        flex-direction: row;
    }
}

body>footer .shortcuts.secondary a,
body>#recite-wrapper>footer .shortcuts.secondary a {
    display: block;

    @include media-breakpoint-down(xs) {
        font-size: 16px;
        line-height: 20px;
        padding: 5px 5px 5px 0;
    }

    @include media-breakpoint-up(sm) {
        flex: 1 1 auto;
        text-align: right;
    }
}

.socialmedia {
    @include media-breakpoint-down(xs) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
    }

    grid-area: social;
    justify-self: end;
}

.socialmedia .title {
    font-size: 26px;
    font-weight: 900;
    line-height: 32px;
    text-align: right;
    text-transform: uppercase;

    @include media-breakpoint-down(xs) {
        font-size: 15px;
        line-height: 18px;
    }
}

.socialmedia .links {
    text-align: right
}

.socialmedia .links a {
    display: inline-block;
    padding: 15px 0;

    &:hover path {
        fill: #47bcca;
    }
}

.socialmedia .links a+a {
    margin-left: 30px;

    @include media-breakpoint-down(xs) {
        margin-left: 15px;
    }
}

button.totop {
    position: absolute;
    top: 30px;
    right: 15px;
    border: 0;
    height: auto;
    padding-top: 27px;
    width: 34px;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 1.38px;
    line-height: 13px;
    text-align: center;
    color: #fff;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='16'%3E%3Cpath fill='none' fill-rule='nonzero' stroke='%23FFF' d='M1 16L17 0l16 16'/%3E%3C/svg%3E") no-repeat 50% 0;

    @include media-breakpoint-down(xs) {
        display: none;
    }

    &:hover {
        color: #47bcca;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='16'%3E%3Cpath fill='none' fill-rule='nonzero' stroke='%2347bcca' d='M1 16L17 0l16 16'/%3E%3C/svg%3E");
    }

    &:focus {
        outline: none;
    }
}

body>footer .legal,
body>#recite-wrapper>footer .legal {
    @include media-breakpoint-down(xs) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
        -ms-grid-column-span: 2;
    }

    grid-area: legal;
}