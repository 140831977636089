.exploration {
    @include make-container();
    @include make-container-max-widths();

    .exploration-block {
        @include make-row();

        .heading {
            padding-top: 12px;
            @include make-col-ready();

            @include media-breakpoint-up(sm) {
                @include make-col(3);
            }

            @include media-breakpoint-up(lg) {
                @include make-col(2);
            }
        }

        .content {
            @include make-col-ready();
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                @include make-col-offset(1,11);
            }

            table {
                margin-top: 0;
            }
        }
    }

    .exploration-block + .exploration-block {
        margin-top: 50px;
    }
}


.exploration-block {
    .heading {
        .header {
            .descriptor {
                @include ts-med();
            }

            h3 {
                @include ts-h3();
            }
        }

        .mediumnumber {
            .number {
                @include ts-medium-number();
            }

            .description {
                @include ts-med();
            }
        }
    }
}