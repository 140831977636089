.accordion {
    margin-bottom: 20px;

    .acc-title {
        display: block;
        width: 100%;
        padding: 30px 80px 30px 30px;
        border: 0;
        margin: 20px 0 0 0;
        background: #ebf9fa url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13'%3E%3Cg fill='%23000' fill-rule='evenodd'%3E%3Cpath d='M1.062.942L.5 1.504l10.938 10.813.562-.563z'/%3E%3Cpath d='M11.803 11.816l-.562-.563L22.117.5l.562.563z'/%3E%3C/g%3E%3C/svg%3E") no-repeat right 30px center;
        color: #000;
        @include ts-h4();
        text-align: left;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13'%3E%3Cg fill='%231dbfcd' fill-rule='evenodd'%3E%3Cpath d='M1.062.942L.5 1.504l10.938 10.813.562-.563z'/%3E%3Cpath d='M11.803 11.816l-.562-.563L22.117.5l.562.563z'/%3E%3C/g%3E%3C/svg%3E");
            color: #1dbfcd;
        }

        &:focus {
            outline: none;
        }

        &[aria-expanded="true"] {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13'%3E%3Cg fill='%23000' fill-rule='evenodd'%3E%3Cpath d='M1.062 12.058L.5 11.496 11.438.683l.562.563z'/%3E%3Cpath d='M11.803 1.184l-.562.563L22.117 12.5l.562-.563z'/%3E%3C/g%3E%3C/svg%3E");

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='13'%3E%3Cg fill='%231dbfcd' fill-rule='evenodd'%3E%3Cpath d='M1.062 12.058L.5 11.496 11.438.683l.562.563z'/%3E%3Cpath d='M11.803 1.184l-.562.563L22.117 12.5l.562-.563z'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    .acc-cnt + .acc-title {
        margin-top: 14px;
    }

    .acc-cnt {
        @include ts-17-25();
        background-color: #ebf9fa;

        .wrapper {
            padding: 0 30px 60px 30px;
            border-bottom: 10px solid #1dbfcd;
        }
    }
}
