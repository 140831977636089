.bi-block {
    position: relative;
    padding-top: 100px;
    padding-bottom: 120px;

    .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            height: auto;
            width: 2000px;
        }
    }

    &.dark .richtext {
        color: #fff;
    }

    &.dark .bignumber {
        color: #fff;
    }
}
