.content-block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.richtext {
  @include ts-17-25();
  color: #000;

  a {
    text-decoration: underline;
    color: #000;

    &:hover {
      color: #1dbfcd;
    }
  }

  h4 {
    @include ts-17-25-heading-a();
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }

  ul {
    padding-left: 20px;
    list-style: square;
  }
}
