


.listviewheading {
	margin-bottom: 24px;
	@include ts-h4();
	text-transform: uppercase;

	&.accent-under::after {
		bottom: -5px;
	}
}

@mixin bignumber() {
    .number {
        @include ts-big-number();
        @media (max-width: 768px) {
           font-size: 30px;
           line-height: 40px;
        }
        small {
            @include ts-22-30-heading-b();
        }
    }
    .description + .number {
        margin-top: -10px;
    }

    .description {
        @include ts-15-20();
        max-width: 200px;

        &.accent-under::after {
            bottom: -5px;
        }
    }
}


.bignumber {
    @include bignumber();
}