.video.screen {
    height: auto;
}

.video.modal {
    
}
a.modal {
    z-index: 1;
}

.video.link {
    
}

.video.screen {
	display: block;
	position: relative;
	 
	img {
		display: block;
		width: 100%;
		height: auto;
	}

	&::after {
		display: block;
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		width: 45px;
		height: 52px;
		background: #1dbfcd url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.895' height='17.965'%3E%3Cpath fill='%23FFF' d='M0 0v17.965l14.895-8.983z'/%3E%3C/svg%3E") no-repeat 50% 50%;
	}

	&:hover::after {
		background-color: #000;
	}
}


div.modal {
    button.close {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        padding: 0;
        width: 40px;
        height: 40px;
        background-color: #000;
        z-index: 2;

        &:hover svg path {
            stroke: #1dbfcd;
        }

        svg {
            vertical-align: baseline;
            margin-top: 5px;
        }
    }
}

.modal-backdrop.show {
    opacity: 0.8;
    z-index: 1049;
}