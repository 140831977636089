.cval-block {
    @include make-container();
    @include make-container-max-widths();
    padding-left: 30px;
    padding-right: 30px;

    .wrapper {
        @include make-row();
    }

    .image {
        @include make-col-ready();

        @include media-breakpoint-up(md) {
            @include make-col(1);
            @include make-col-offset(2,11);
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }

    .copy {
        @include make-col-ready();

        @include media-breakpoint-up(md) {
            @include make-col(5);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(11);
        }
    }
}
