.crisis-top {
    background: linear-gradient(0deg,rgba(0,0,0,1) 0,rgba(0,0,0,0.85) 75%);
    color: #FFF;
    position:relative;
    
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: 50% 100%;
        z-index: 0;

        @include media-breakpoint-down(xs) {

            
                display: none;
            
        }

        // for picture element
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            object-position: 50% 100%;
        }
    }

    .crisis-left {
        .header  {
            @include media-breakpoint-down(xs) {
            margin-top:200px;
        }
    }
    }

    .crisis-right {
        @include media-breakpoint-up(sm) {
            margin-top:305px;
        }
        @include media-breakpoint-down(sm) {
            padding-left:30px;
            padding-right:30px;
        }
    }
    
    .latest-statement-heading {
        font-size: 26px;
        line-height: 22px;
        padding-bottom: 12px;
        margin-bottom: 5px;
        position: relative;

        h2 {
            text-transform: uppercase;
        font-size: 26px;
        font-weight: 900;
        line-height: 22px;
        padding-bottom: 0;
        margin-bottom: 0;
        position: relative;
        }
    }

        .latest-statement {

            margin-bottom:70px;

             

            .date {
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 6px;
                font-size: 15px;
                font-weight: 300;
                line-height: 20px;
                text-transform: uppercase;
                color: #fff;
    
                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 14px;
                    height: 3px;
                    background-color: #1dbfcd;
                }
            }

            .copy {
                padding-bottom: 12px;
                margin-bottom: 5px;
                position: relative;
                text-transform: uppercase;
                font-size: 50px;
                font-weight: 900;
                line-height: 40px;

                a {
                    color: #fff;
                }

                a:hover {
                    text-decoration: none;
                    color: #1dbfcd !important;
                    
                }
            }
        }

        .contacts {

            margin-bottom:70px;

                h2 {
                    text-transform: uppercase;
                font-size: 26px;
                font-weight: 900;
                line-height: 22px;
                padding-bottom: 12px;
                margin-bottom: 5px;
                position: relative;
                }

                h3 {
                    text-transform: none;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 25px;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 22px;
                    font-weight: 300;
                    line-height: 30px;

                    a {
                        color: #fff;
                    }
    
                    a:hover {
                        text-decoration: none;
                        color: #1dbfcd !important;
                    }

                }
            }

        .info {

            margin-bottom:70px;

            h2 {
                text-transform: uppercase;
                font-size: 26px;
                font-weight: 900;
                line-height: 22px;
                padding-bottom: 12px;
                margin-bottom: 5px;
                position: relative;
            }

            p {
                text-transform: none;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 25px;
                    color: #fff;
                 &.info-links {
                     margin-top:-0.5em;
                     a {
                         display:block;
                         padding-left:20px;
                     }
                 }
                    a {
                        color: #fff;
                        font-size: 15px;
                        line-height: 20px;

                        &.download {
                            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                            }

                        &.external {
                            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                        }
                        
                        &.internal {

                            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                        }

                        
                        
                    }
    
                    a:hover {
                        text-decoration: none;
                        color: #1dbfcd !important;
                        &.download {
                            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                            }

                        &.external {
                            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                        }
                        &.internal {

                            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                        }

                    }
            }

        }

        .bignumber {

            margin:30px 0 70px 0;

            .number {
                line-height:70px;
                padding-top: 0px;
            } 
            .heading {
                font-size: 26px;
                line-height: 22px;
                padding-bottom: 12px;
                margin-bottom: 5px;
                position: relative;
            }

            
        }

    }


    

/*
.crisis-top ul.bh-dark-nav {

    padding-inline-start: 0px;
    margin: 30px 0 0 0;
    position: relative;
    padding: 0;
    left:0;
    pointer-events: all;
    transform: none;
    text-align: left;
    width:clear;
    right:0;
    li {
        padding: 0px;
        margin: 0 0 20px 0;
        border-top:  none;
        text-align: left;
        a {
            display: inline-block;
            border-color: #fff;
            border-style: solid;
            border-width: 1px;
            background-color: transparent;
            color: #fff;
            padding: 16px;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            border-radius: 0;
            width: 233px;
            max-width: 100%;
            width: 300px;
        
            &::before {
                background: none;
                display: none;
            }
        
            &.btn-outline-light-download {
                border-color: #fff;
                background-color: transparent;
                color: #fff;
        
                &::before {
                    position: relative;
                    display: inline-block;
                    content: '';
                    width: 13px;
                    height: 16px;
                    margin-right: 5px;
                    bottom:-3px;
                    vertical-align: top;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
                }
        
                &:hover,
                &:active,
                &.active,
                &:not(:disabled):not(.disabled).active,
                &:not(:disabled):not(.disabled):active,
                &:focus {
                    border-color: #1dbfcd;
                    background-color: transparent;
                    color: #1dbfcd;
                    outline: 0;
                    box-shadow: none;
        
                    &::before {
        
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
                    }
                }
        
            }
        
        }
    }

}



*/





