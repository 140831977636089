.socialmedia-cards {
    background: #f3f3f3;

    @include media-breakpoint-up(sm) {
        background: #f3f3f3 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABDCAMAAAAF6sPxAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADBQTFRF8/PzHb/NUcrVveXodtTd0ersMsPQlNvh6/Hxhdjfpt/k5O/w8vPz7/Ly3O3uI8DOlDT+4wAAAoZJREFUeNrEmOnO7CAIhhWta9X7v9tT61Lsl244yfFXzYxPQF4RZIwxbwGkYDMjxNA+F8XzkCsZ5mXiSZr92xQa545sGuzrYTdQVxpXVPMctkc2HF+IOKjrJZ7M42CwLhkirhHsHliObWVs9f7jJi6pmBP3ma2R2GfGboFW9puhLvNUU67OUoGdFqtqwH/ixW1J7DNRt5GtPS7w6CEocG1XzLaC/cWJHmX+cOiKG/Yed2iw//M2msnf4g4Ntjg/aE28te4dbrnFuQPn3pxTuN87r3o+eFKeTk1c17ge2iTenNT+pyscE7t9VeHroqV1ZgLHwhYOGapSi6V6peNydIvmROpRDvO4qO41/RGHNNhyD4uRivMJ4bjeF20EMDScwLTysx38/oZzf3EwZK7/ilsGnGx3QlpouBUwTrRbxVKFgr2FdRqHhKeq3KZwwTbb2n5N4ba9lzmpuX5iJ3Hjmh/jfL7elfa/wYmaYVrxMIc7dFMT/xQOZz/lp3EWnxFLxGUPS4ZTGKdouJDaBe6HhMANCee7a3HERZqz0LKJGWgp0HC5IbLn2upIpp8jGxZ/ll0rhyi4c6OEuqUpHNJxzX5zuK0trNdGcd8DqgEJuC0wWkodj/LuKPBIOFwsjo5f43IeUk/Fohg1c42L6cgZz01da3YucRqVONdDnTR4iZOvmpTXOPeqDYBTfXCJK+qHh472SKalp76OrJFKyaem4ug/dI/silsY+NYQtzK8FOEiuwQCm67it4a9FAgrYtcbzg1V0uthYus+m+cq4DBRX6HEEOWQ+CvpPp7fIq/1t7h+AKmPPMt4fuuDm2XUAWODnjtDpSee7+D09uJjmHlbNBqgFKn/BBgAaoEVJiQHdk8AAAAASUVORK5CYII=') repeat-x 50% 50%;
    }

    .header {
        margin-right: auto;
        margin-left: auto;
        padding-right: 30px;
        padding-left: 30px;

        @include make-container-max-widths();
    }

    .media {
        padding-top: 50px;
        padding-bottom: 50px;

        @include media-breakpoint-down(xs) {
            @include make-container();
            @include make-container-max-widths();
        }

        @include media-breakpoint-up(sm) {
            @include make-container(60px);
            @include make-container-max-widths();
        }

        overflow: hidden;

        .cards {


            &:not(.active) {
                display: none;
            }

            @include media-breakpoint-down(xs) {
                @include make-row();
            }

            @include media-breakpoint-up(sm) {
                @include make-row(60px);
            }

            flex: 1 1 auto;

            .card {
                @include media-breakpoint-down(sm) {
                    @include make-col-ready();
                    @include make-col(11,11);
                    padding-right: 0;
                    padding-left: 0;
                }

                @include media-breakpoint-up(md) {
                    @include make-col-ready(60px);
                    @include make-col(1,3);
                    min-height: 400px;
                }

                background-color: #fff;
                background-clip: content-box;
            }

            .card + .card {
                @include media-breakpoint-down(sm) {
                    margin-top: 2px;
                }
            }
        }
    }
}
