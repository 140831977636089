
.operations-landing-top {

    #map {
    }

    .operations-map {
        position: relative;
        width: 100%;
        height: 590px;
        z-index: 1;
        display: flex;
        justify-content: center;
        transition: height 0.3s;


        .stats {
            width: 375px;
            position: absolute;
            bottom: 85px;
            right: 0;
            z-index: 3;
            pointer-events: none;



            @include media-breakpoint-down(sm) {
                width: 100%;
                max-width: map-get($container-max-widths, 'sm');
                right: auto;
                display: flex;
                flex-direction: row;
                padding-left: 30px;
                padding-right: 30px;
            }

            @include media-breakpoint-down(xs) {
                width: 100%;
                max-width: 100%;
                padding-left: 30px;
                padding-right: 30px;
            }

            @media (max-width: 349px) {
                display: none;
            }


            .bignumber {
                @include bignumber();
                color: #fff;

                @include media-breakpoint-down(sm) {
                    flex: 1 1 auto;
                }

                .number {
                    @media (max-width: 490px) {
                        font-size: 50px;
                        line-height: 70px;
                    }
                }
            }
        }

        .cnt {
            position: relative;
            max-width: 100%;
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            z-index: 3;
            pointer-events: none;
            align-items: flex-end;
            padding-bottom: 85px;
            padding-left: 30px;
            padding-right: 30px;

            @include media-breakpoint-down(sm) {
                padding-top: 125px;
                align-items: flex-start;
            }

            @include media-breakpoint-down(xs) {
                padding-left: 30px;
                padding-right: 30px;
            }

            @include media-breakpoint-up(sm) {
                max-width: map-get($container-max-widths, 'sm');
            }

            @include media-breakpoint-up(md) {
                max-width: map-get($container-max-widths, 'md');
            }

            @include media-breakpoint-up(lg) {
                max-width: map-get($container-max-widths, 'lg');
            }

            @include media-breakpoint-up(xl) {
                max-width: map-get($container-max-widths, 'xl');
            }


            .copy {
                .descriptor {
                    position: relative;
                    padding-bottom: 10px;
                    margin-bottom: 6px;
                    color: #fff;
                    @include ts-descriptorle();
                    font-weight: 700;
                    text-transform: uppercase;

                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 14px;
                        height: 3px;
                        background-color: #1dbfcd;
                    }

                    .thin {
                        font-weight: 300;
                    }

                    a {
                        color: #fff;
                        pointer-events: all;

                        &:hover {
                            color: #47BCCA;
                            text-decoration: none;
                        }
                    }
                }

                .header {
                    max-width: 503px;
                    color: #fff;
                    @include ts-h1();

                    @at-root .norwegian-site #{&} {
                        @include ts-no-h1();
                    }

                    text-transform: uppercase;

                    @media (max-width: 400px) {
                        max-width: 90%;
                    }

                    @include media-breakpoint-down(sm) {
                        @include ts-h3();

                        @at-root .norwegian-site #{&} {
                            @include ts-no-h3();
                        }
                    }
                }

                .preamble {
                    max-width: 363px;
                    margin-top: 20px;

                    @include ts-17-25();
                    color: #fff;

                    @at-root .dark#{&} {
                        color: #000;
                    }

                    & > *:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        #map {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            .marker {
                border: none;
                cursor: pointer;
                height: 35px;
                width: 35px;

                @include media-breakpoint-down(xs) {
                    display: none;
                }

                &:hover {
                    svg g {
                        stroke: #1dbfcd;
                    }
                }
            }

            .mapboxgl-popup {
                font-family: "Flama", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

                h3 {
                    @include ts-22-30();
                }

                h4 {
                }
            }
        }
    }
}

