.memberships {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .header {
        min-width: 18.18%;
        padding: 20px 0;
        @include ts-med();

        @include media-breakpoint-down(sm) {
            width: 100%;
            text-align: center;
        }
    }

    .logogrid {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        li {
            flex: 0 1 20%;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a {
            display: inline-block;
        }

        img {
            display: block;
            width: 100%;
            min-width: 100px;
            height: auto;
        }
    }
}
