.contact-location-map {
    position: relative;
    background-color: #000;
    @include media-breakpoint-down(sm) {
        display: none;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    .label {
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 30px;
        color: #fff;
        text-transform: uppercase;
        @include ts-h2();

        @include media-breakpoint-down(sm) {
            @include ts-h3();
        }

        @media (max-width: 340px) {
            font-size: 45px;
        }
    }
}
