.latest-posts {
    background-color: #F3F3F3;
    overflow: auto;
    padding-top: 60px;
    padding-bottom: 60px;


    .header {
        @include make-container-max-widths();
        margin-right: auto;
        margin-left: auto;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 40px;
    }

    .wrapper {
        @include make-container(60px);
        @include make-container-max-widths();
        overflow: hidden;

        .items {
            @include make-row(60px);

            .post-item {
                @include make-col-ready(60px);

                @include media-breakpoint-down(xs) {
                    @include make-col(11,11);
                }

                @include media-breakpoint-up(sm) {
                    @include make-col(1,3);
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .post-item + .post-item {
                @include media-breakpoint-down(xs) {
                    margin-top: 30px;
                }
            }
        }
    }
}
