.col-full, .col-sm-full, .col-md-full, .col-lg-full, .col-xl-full,
.col-half, .col-sm-half, .col-md-half, .col-lg-half, .col-xl-half,
.col-third, .col-sm-third, .col-md-third, .col-lg-third, .col-xl-third,
.col-twothird, .col-sm-twothird, .col-md-twothird, .col-lg-twothird, .col-xl-twothird,
.col-quarter, .col-sm-quarter, .col-md-quarter, .col-lg-quarter, .col-xl-quarter {
    @include make-col-ready(20px);
}

/* HALF */
.col-half {
    @include make-col(1,2);
}
.col-sm-half {
    @include media-breakpoint-up(sm) {
        @include make-col(1,2);
    }
}
.col-md-half {
    @include media-breakpoint-up(md) {
        @include make-col(1,2);
    }
}
.col-lg-half {
    @include media-breakpoint-up(lg) {
        @include make-col(1,2);
    }
}
.col-xl-half {
    @include media-breakpoint-up(xl) {
        @include make-col(1,2);
    }
}

/* THIRD */
.col-third {
    @include make-col(1,3);
}
@include media-breakpoint-up(sm) {
    .col-sm-third {
        @include make-col(1,3);
    }
}
@include media-breakpoint-up(md) {
    .col-md-third {
        @include make-col(1,3);
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-third {
        @include make-col(1,3);
    }
}

@include media-breakpoint-up(xl) {
    .col-xl-third {
        @include make-col(1,3);
    }
}


.col-twothird {
    @include make-col(2,3);
}

@include media-breakpoint-up(sm) {
    .col-sm-twothird {
        @include make-col(2,3);
    }
}

@include media-breakpoint-up(md) {
    .col-md-twothird {
        @include make-col(2,3);
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-twothird {
        @include make-col(2,3);
    }
}

@include media-breakpoint-up(xl) {
    .col-xl-twothird {
        @include make-col(2,3);
    }
}


/* QUARTER */
.col-quarter {
    @include make-col(1,3);
}

@include media-breakpoint-up(sm) {
    .col-sm-quarter {
        @include make-col(1,4);
    }
}

@include media-breakpoint-up(md) {
    .col-md-quarter {
        @include make-col(1,4);
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-quarter {
        @include make-col(1,4);
    }
}

@include media-breakpoint-up(xl) {
    .col-xl-quarter {
        @include make-col(1,4);
    }
}


/* FULL */
.col-full {
    @include make-col(1,1);
}

@include media-breakpoint-up(sm) {
    .col-sm-full {
        @include make-col(1,1);
    }
}

@include media-breakpoint-up(md) {
    .col-md-full {
        @include make-col(1,1);
    }
}

@include media-breakpoint-up(lg) {
    .col-lg-full {
        @include make-col(1,1);
    }
}

@include media-breakpoint-up(xl) {
    .col-xl-full {
        @include make-col(1,1);
    }
}

/* CONTAINER MODS */

main > .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

main > .container {
    padding-left: 30px;
    padding-right: 30px;
}



/* SPACING MODS */
@include media-breakpoint-down(xs) {
    .mb-5, .my-5 {
        margin-bottom: 1rem !important;
    }

    .mt-5, .my-5 {
            margin-top: 1rem !important;
    }

    .pb-5, .py-5 {
        padding-bottom: 1rem !important;
    }

    .pt-5, .py-5 {
        padding-top: 1rem !important;
    }
}
