@keyframes circle--animation {
    0% {
        stroke-dashoffset: 82;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

.carousel-slim {
    .timer {
        position: absolute;
        top: calc(50% - 8px);
        right: 150px;

        @include media-breakpoint-down(sm) {
            right: 100px;
            top: 50%;
        }

        transform: translateY(-50%);
        z-index: 100;
        width: 27px;
        height: 27px;

        svg {
            position: relative;
            width: 27px;
            height: 27px;
            margin: 0;
            display: block;

            circle.prog {
                fill: transparent;
                stroke: #fff;
                stroke-width: 2;
                stroke-dasharray: 82;
                stroke-dashoffset: 82;
                stroke-linecap: round;
                transform-origin: 50% 50%;
                transform: rotateZ(-90deg);
            }

            circle.bg {
                fill: transparent;
                stroke: rgba(255, 255, 255, 0.5);
                stroke-width: 2;
            }
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            border: 0;
            margin: 0;
            width: 27px;
            height: 27px;
            background: transparent;

            &:focus {
                outline: none;
            }

            &::before {
                position: absolute;
                display: block;
                content: '';
                width: 10px;
                height: 12px;
                border-left: 3px solid #fff;
                border-right: 3px solid #fff;
                top: 7px;
                left: 9px;
                transition: border-color 0.3s;
            }

            &:hover::before {
                border-color: #1dbfcd;
            }

            &::after {
                position: absolute;
                display: none;
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 0 7px 10px;
                border-color: transparent transparent transparent #fff;
                top: 6px;
                left: 10px;
                transition: border-left-color 0.3s;
            }

            &:hover::after {
                border-color: transparent transparent transparent #1dbfcd;
            }
        }
    }
}

.carousel-slim .timer.animated svg circle.prog {
    animation: 3s linear infinite both circle--animation;
}

.carousel-slim .timer.paused button::before {
    display: none;
}

.carousel-slim .timer.paused button::after {
    display: block;
}

.carousel-slim .timer.paused svg circle.prog {
    animation: none;
}


//IE HACK - IE11 doesn't support transforms on svg
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
    .timer.animated svg circle.prog {
        display: none;
    }
}