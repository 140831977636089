.imgcard {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 440px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        object-fit: cover;
        height: 690px;
        width: 100%;
        object-position: 50% 100%;
    }

    .copy {
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: 240px;
        width: 70%;
        max-width: 730px;
        padding: 30px;
        height: auto;
        z-index: 2;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        .descriptor {
            color: #fff;
            @include ts-descriptorle();
            padding-bottom: 8px;
            margin-bottom: 9px;
            @include header-accent($placement: below);
        }

        .header {
            color: #fff;
            @include ts-h3();

            @include media-breakpoint-only(sm) {
                font-size: 40px;
                line-height: 33px;
            }
        }
    }
}

a.imgcard {
    &:hover {
        .copy .header {
            color: #1dbfcd;
        }
    }
}

.imgcard.tint-white::before,
.imgcard.tint-black::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}


.imgcard.tint-white::before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 100%);
}

.imgcard.tint-black::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
}

.imgcard.tint-black .copy {
    text-shadow: 0 2px 4px rgba(0,0,0,0.15);
}

.imgcard.dark .copy {
    .descriptor {
        color: #000;
    }

    .header {
        color: #000;
    }
}

.imgcard.from {
    .copy {
        width: 100%;
        max-width: 100%;

        .descriptor {
            padding-left: 16px;
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
            text-transform: none;
            &::after {
                display: none;
            }
        }

        .header {
            @include ts-h2();
            max-width: 50%;

            @include media-breakpoint-down(md) {
                @include ts-h2();
                max-width: 100%;
            }

            @include media-breakpoint-down(sm) {
                @include ts-h3();
                max-width: 100%;
            }
        }
    }
}
