nav.ar-nav {
    position: fixed;
    top: 111px;
    right: 0;
    height: auto;
    max-height: 82px;
    overflow: hidden;
    z-index: 1048;

    @include media-breakpoint-down(xs) {
        width: 100%;
        top: 102px;
    }

    background-color: #fff;
    z-index: 1;

    &:not(.contracting) {
        transition: max-height 0.3s ease-in-out;
    }

    &.contracting {
        transition: max-height 0.3s ease-in-out 0.3s !important;
    }

    &.open {
        max-height: 600px;
        transition: max-height 0.3s ease-in-out;
    }

    a.ar-trigger {
        position: relative;
        display: block;
        padding: 31px 0 31px 77px;

        @include media-breakpoint-down(xs) {
            padding: 31px 0 31px 32px;

            @at-root nav.ar-nav.expanded a.ar-trigger {
                padding: 31px 0 31px 72px;
            }
        }

        text-align: left;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        z-index: 1;

        &::before {
            display: block;
            position: absolute;
            right: 28px;
            top: 50%;
            content: '';
            width: 12px;
            height: 2px;
            background-color: #424242;
        }

        &::after {
            display: block;
            position: absolute;
            right: 33px;
            top: 36px;
            content: '';
            width: 2px;
            height: 12px;
            background-color: #424242;
        }

        &:hover {
            &::before {
                background-color: #1dbfcd;
            }

            &::after {
                background-color: #1dbfcd;
            }
        }
    }

    &.open a.ar-trigger::after {
        display: none;
    }

    button.backbtn {
        position: absolute;
        top: 0;
        left: 14px;
        display: none;
        z-index: 2;
        width: 60px;
        height: 82px;
        border: 0;
        padding: 0;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 14'%3E%3Cpath fill='none' stroke='%23000000' d='M7 1L1 7l6 6.008'/%3E%3C/svg%3E") no-repeat 50% 50%;
        background-size: 8px;

        &:hover {
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 14'%3E%3Cpath fill='none' stroke='%231dbfcd' d='M7 1L1 7l6 6.008'/%3E%3C/svg%3E") no-repeat 50% 50%;
            background-size: 8px;
        }

        @include media-breakpoint-down(xs) {
            left: 0;
            width: 72px;
        }
    }

    &.expanded button.backbtn {
        display: block;
    }

    .wrapper {}

    ul {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(xs) {
            justify-content: flex-start;
        }

        right: 0;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
            width: 412px;
        }

        height: 100%;
        padding: 0 0 20px 0;
        margin: 0;
        list-style: none;
        z-index: 2;
        transition: right 0.3s ease-in-out;
    }

    &.expanded ul {
        @include media-breakpoint-down(sm) {
            right: 100%;
        }

        @include media-breakpoint-up(sm) {
            right: 412px;
        }
    }

    ul ul {
        display: none;
        z-index: 1;
        position: absolute;
        top: 0;
        height: auto;

        @include media-breakpoint-down(sm) {
            left: 100%;
        }

        @include media-breakpoint-up(sm) {
            left: 412px;
        }
    }

    ul .expanded ul {
        display: flex;
    }

    li {
        width: 100%;
        padding: 0;
    }

    a {
        display: block;
        width: 100%;
        padding: 9px 44px 9px 77px;

        @include media-breakpoint-down(xs) {
            padding: 9px 31px 9px 32px;
        }

        font-family: "Flama";
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        color: #000;
        transition: color 0.3s;
    }

    a:hover,
    .expanded>a,
    .active>a {
        text-decoration: none;
        color: #1dbfcd;
    }

    .active>a {
        text-decoration: none;
        color: #000;
        font-weight: 500;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 3'%3E%3Cpath fill='%231DBFCD' d='M-.003-.024h14v3h-14z'/%3E%3C/svg%3E") no-repeat 77px 100%;
        background-size: 14px;

        @include media-breakpoint-down(xs) {
            background-position: 32px 100%;
        }
    }

    .subnav>a {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 14'%3E%3Cpath fill='none' stroke='%23231F20' d='M1 1l6 6-6 6.008'/%3E%3C/svg%3E") no-repeat center right 33px;
        background-size: 8px;
    }

    .parent>a {
        text-decoration: none;
        color: #000;
        font-weight: 500;
    }

    .landing>a,
    .back>a {}

    .landing>a {
        &::before {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 1em;
            content: '';
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23fff'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 5px;

            @include media-breakpoint-down(xs) {
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%23FFF' d='M12.511 12.172L1.758 23.048l-.563-.562 10.187-10.303L.753 1.431 1.316.87l11.195 11.302z'/%3E%3C/svg%3E") no-repeat 0 11px;
            }
        }

        &:hover::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%231dbfcd'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");

            @include media-breakpoint-down(xs) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%231dbfcd' d='M12.511 12.172L1.758 23.048l-.563-.562 10.187-10.303L.753 1.431 1.316.87l11.195 11.302z'/%3E%3C/svg%3E");
            }
        }
    }


    .back>a {}
}