.carousel-slim .cntnr {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    left: 0;
    top: 0;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 247px;
        height: 100%;
        background: linear-gradient(90deg, rgba(0,0,0,0.23) 0%, rgba(0,0,0,0) 98.13%, rgba(0,0,0,0.03) 100%);
        z-index: 4;
        pointer-events: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}
