.quote .copy {
    color: #47BCCA;
    @include ts-h2();

    @include media-breakpoint-down(sm) {
        @include ts-h3();
    }

    background: transparent url(../../static/img/quote-blk.svg) no-repeat 0 0;
    padding: 43px 0 0 0;
}



.quote.dark .copy {
    color: #000;

        @include ts-h3();

    background-image: none;
}



.quote.small .copy {
    @include ts-h3();
}

.quote .author {
    color: #000;
    @include ts-h4();
    margin-top: 10px;
    padding-top: 10px;
    @include header-accent($placement: above);
}
.quote .title {
    margin-top: 2px;
    color: #000;
    @include ts-22-30();
}