@import "carousel-slim-timer";
@import "carousel-slim-buttons";
@import "carousel-slim-cntnr";
@import "carousel-slim-item";

.carousel-slim {
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 23.58%;
    box-shadow: inset -7px -1px 160px 0 #E1E1E1;
}