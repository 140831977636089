.contact-locations {
    position: relative;
    padding: 50px 30px 30px 30px;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
        padding: 0;
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 7px;
        right: 7px;
        bottom: 0;
        background-color: #f3f3f3;
        z-index: -1;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    & > div {
    }
}


.contact-location-details {
    margin-bottom: 30px;
    font-size: 17px;

    h4 {
        padding-bottom: 12px;
        margin-bottom: 5px;
        @include header-accent($placement: below);
    }

    a {
        padding-left: 16px;
        color: #000;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 65%;

        &:hover {
            color: #47bcca;
            text-decoration: none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}




