@keyframes circle--animation {
    0% {
        stroke-dashoffset: 82;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

.timer {
    position: absolute;
    top: 50%;
    right: 485px;

    @include media-breakpoint-down(sm) {
        right: 100px;
    }

    transform: translateY(-50%);
    z-index: 100;
    width: 40px;
    height: 40px;

    svg {
        position: relative;
        width: 40px;
        height: 40px;
        margin: 0;
        display: block;

        circle.prog {
            fill: transparent;
            stroke: #fff;
            stroke-width: 2;
            stroke-dasharray: 82;
            stroke-dashoffset: 82;
            stroke-linecap: round;
            transform-origin: 50% 50%;
            transform: rotateZ(-90deg);
        }

        circle.bg {
            fill: transparent;
            stroke: rgba(255,255,255,0.5);
            stroke-width: 2;
        }
    }

    button {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        border: 0;
        margin: 0;
        width: 40px;
        height: 40px;
        background: transparent;

        &:focus {
            outline: none;
        }

        &::before {
            position: absolute;
            display: block;
            content: '';
            width: 12px;
            height: 14px;
            border-left: 4px solid #fff;
            border-right: 4px solid #fff;
            top: 13px;
            left: 14px;
            transition: border-color 0.3s;
        }

        &:hover::before {
            border-color: #1dbfcd;
        }

        &::after {
            position: absolute;
            display: none;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 0 7px 10px;
            border-color: transparent transparent transparent #fff;
            top: 13px;
            left: 16px;
            transition: border-left-color 0.3s;
        }

        &:hover::after {
            border-color: transparent transparent transparent #1dbfcd;
        }
    }
}

.timer.animated svg circle.prog {
    animation: 10s linear infinite both circle--animation;
}

.timer.paused button::before {
    display: none;
}

.timer.paused button::after {
    display: block;
}

.timer.paused svg circle.prog {
    animation: none;
}


//IE HACK - IE11 doesn't support transforms on svg
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .timer.animated svg circle.prog {
        display: none;
    }
}