.subhomehero {
  background-color: #000;

  .cntnr {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    position: relative;
    width: 25%;
    min-height: 800px;
    height: 100%;
    justify-content: center;
    transition: 0.3s ease;
    overflow: hidden;

    &:before {
      content: "";
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      height: 100%;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      justify-content: flex-end;
      padding-bottom: 85px;
      min-height: 600px;
    }

    &.big {
      width: 25%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .copy {
    margin-left: 30px;
    z-index: 5;
    bottom: 60px;
    position: absolute;

    .header {
      width: calc(100% - 126px);
      max-width: 622px;
      color: #fff;

      font-size: 42px;
      font-style: normal;
      font-weight: 850;
      line-height: 35px; /* 83.333% */
      letter-spacing: -1px;

      text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      text-transform: uppercase;

      @at-root .homehero .item.super .copy .header {
        font-size: 165px;
        letter-spacing: -3px;
        line-height: 0.76;
      }

      @at-root .homehero .item.small .copy .header {
        width: 74%;
        max-width: 260px;
        font-size: 42px;
        font-weight: 900;
        letter-spacing: -0.5px;
        line-height: 35px;
      }

      @at-root .homehero .item.smallrightout .copy .header {
        width: 74%;
        max-width: 260px;
        font-size: 42px;
        font-weight: 900;
        letter-spacing: -0.5px;
        line-height: 35px;
      }
    }

    .preamble {
      margin-top: 20px;
      height: auto;
      width: 350px;
      color: #fff;
      font-size: 22px;
      font-weight: 300;
      line-height: 30px;

      @at-root .homehero .item.small .copy .preamble {
        overflow: hidden;
        height: 0;
        margin-top: 0;
      }

      @at-root .homehero .item.smallrightout .copy .preamble {
        overflow: hidden;
        height: 0;
        margin-top: 0;
      }

      @include media-breakpoint-down(xs) {
        //display: none;
        p {
          display: none;
        }
      }

      .btn {
        margin-top: 16px;
      }
    }
  }
}

.hero {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);

  @include media-breakpoint-down(xs) {
    margin-top: 102px;
    height: 520px;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 130px;
    min-height: 600px;
    padding-bottom: 4rem;
  }

  &.landingpage {
    @include media-breakpoint-down(xs) {
      height: 520px;
    }

    @include media-breakpoint-up(xs) {
      height: 690px;
    }
  }

  &.contact {
    @include media-breakpoint-down(xs) {
      height: 455px;
      /* ?? */
    }

    @include media-breakpoint-up(xs) {
      height: 455px;
    }
  }

  &.crisis {
    height: inherit;
  }

  &.crisis .header {
    margin-top: 35px;
  }

  &.team {
    height: 840px;

    @include media-breakpoint-down(xs) {
      height: 455px;
    }
  }

  &.person {
    @include media-breakpoint-down(xs) {
      height: 400px;
      padding-top: 70px;
    }

    @include media-breakpoint-up(xs) {
      height: 690px;
    }
  }

  &.mini {
    background: #fff;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;

    @include media-breakpoint-down(xs) {
      padding-bottom: 0;
    }
  }

  &.noimg {
    @include media-breakpoint-down(xs) {
      height: 455px;
    }

    @include media-breakpoint-up(sm) {
      height: 455px;
    }
  }

  &.tint-white::before,
  &.tint-black::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.tint-white::before {
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.8) 0,
      rgba(255, 255, 255, 0) 75%
    );
  }

  &.tint-black::before {
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 75%
    );
  }
}

.hero:not(.prvnxt) .cnt,
.hero.landingpage:not(.prvnxt) .cnt,
.hero.contact:not(.prvnxt) .cnt,
.hero.team:not(.prvnxt) .cnt,
.hero.person:not(.prvnxt) .cnt,
.hero.mini:not(.prvnxt) .cnt {
  @include media-breakpoint-down(xs) {
    align-items: flex-end;

    .copy {
      padding-bottom: 85px;
    }
  }
}

.hero {
  /* BG */
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: 50% 100%;
    z-index: 1;

    @include media-breakpoint-down(xs) {
      @at-root .team#{&} {
        display: none;
      }
    }

    // for picture element
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: 50% 50%;
    }
  }

  /* CNT */
  .cnt {
    position: relative;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 3;
    color: #fff;

    @at-root .dark#{&} {
      color: #000;
    }

    @include media-breakpoint-down(xs) {
      @at-root .hero:not(.prvnxt) .cnt {
        align-items: flex-end;
      }

      @at-root .prvnxt#{&} {
        flex-direction: column;
        height: 100%;

        .btns {
          flex: 1 0 auto;
          padding: 23px 0 0 0;
        }

        .copy {
          flex: 0 1 auto;
          padding-bottom: 85px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      max-width: map-get($container-max-widths, "sm");
    }

    @include media-breakpoint-up(md) {
      max-width: map-get($container-max-widths, "md");
    }

    @include media-breakpoint-up(lg) {
      max-width: map-get($container-max-widths, "lg");
    }

    @include media-breakpoint-up(xl) {
      max-width: map-get($container-max-widths, "xl");
    }

    .btns {
      display: none;
      flex-direction: column;
      padding: 30px 0 0 0;

      @at-root .prvnxt#{&} {
        display: flex;
      }

      @at-root .prvnxt.person#{&} {
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      @include media-breakpoint-up(sm) {
        max-width: map-get($container-max-widths, "sm");
      }

      @include media-breakpoint-up(md) {
        max-width: map-get($container-max-widths, "md");
      }

      @include media-breakpoint-up(lg) {
        max-width: map-get($container-max-widths, "lg");
      }

      @include media-breakpoint-up(xl) {
        max-width: map-get($container-max-widths, "xl");
      }

      @media (min-width: map-get($container-max-widths, "sm") + 90px) and (max-width: map-get($grid-breakpoints, "md") - 1px) {
        margin-left: -44px;
      }

      @media (min-width: map-get($container-max-widths, "md") + 90px) and (max-width: map-get($grid-breakpoints, "lg") - 1px) {
        margin-left: -44px;
      }

      @media (min-width: map-get($container-max-widths, "lg") + 90px) and (max-width: map-get($grid-breakpoints, "xl") - 1px) {
        margin-left: -44px;
      }

      @media (min-width: map-get($container-max-widths, "xl") + 90px) {
        margin-left: -44px;
      }

      a {
        display: block;
        width: 44px;
        height: 44px;
        background-color: transparent;
        background-position: 0 50%;
        background-repeat: no-repeat;

        &:focus {
          outline: none;
        }
      }

      .back {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath fill='%23FFF' d='M17.97 16.81L9.899 8.739l8.07-8.07-.646-.646-8.07 8.07-8.07-8.07-.646.646 8.07 8.07-8.07 8.07.646.646 8.07-8.07 8.07 8.07z'/%3E%3C/svg%3E");

        @at-root .dark#{&} {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath fill='%23000' d='M17.97 16.81L9.899 8.739l8.07-8.07-.646-.646-8.07 8.07-8.07-8.07-.646.646 8.07 8.07-8.07 8.07.646.646 8.07-8.07 8.07 8.07z'/%3E%3C/svg%3E");
        }

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath fill='%231dbfcd' d='M17.97 16.81L9.899 8.739l8.07-8.07-.646-.646-8.07 8.07-8.07-8.07-.646.646 8.07 8.07-8.07 8.07.646.646 8.07-8.07 8.07 8.07z'/%3E%3C/svg%3E");
        }
      }

      .prev {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%23FFF' d='M12.753 22.486L2.125 11.737 12.312 1.431 11.749.87 1.021 11.723l11.17 11.325z'/%3E%3C/svg%3E");

        @at-root .dark#{&} {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%23000' d='M12.753 22.486L2.125 11.737 12.312 1.431 11.749.87 1.021 11.723l11.17 11.325z'/%3E%3C/svg%3E");
        }

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%231dbfcd' d='M12.753 22.486L2.125 11.737 12.312 1.431 11.749.87 1.021 11.723l11.17 11.325z'/%3E%3C/svg%3E");
        }
      }

      .next {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%23FFF' d='M12.511 12.172L1.758 23.048l-.563-.562 10.187-10.303L.753 1.431 1.316.87l11.195 11.302z'/%3E%3C/svg%3E");

        @at-root .dark#{&} {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%23000' d='M12.511 12.172L1.758 23.048l-.563-.562 10.187-10.303L.753 1.431 1.316.87l11.195 11.302z'/%3E%3C/svg%3E");
        }

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='23'%3E%3Cpath fill='%231dbfcd' d='M12.511 12.172L1.758 23.048l-.563-.562 10.187-10.303L.753 1.431 1.316.87l11.195 11.302z'/%3E%3C/svg%3E");
        }
      }
    }

    .copy {
      @at-root .tint-black#{&} {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .descriptor {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 6px;
    @include ts-descriptorle();
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;

    @at-root .dark#{&} {
      color: #000;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 14px;
      height: 3px;
      background-color: #1dbfcd;
    }

    .thin {
      font-weight: 300;
    }

    a {
      color: #fff;

      @at-root .dark#{&} {
        color: #000;
      }
    }

    a:hover {
      text-decoration: none;
      color: #1dbfcd !important;
    }
  }

  .header {
    max-width: 700px;
    text-transform: uppercase;
    @include ts-h1();

    @at-root .norwegian-site #{&} {
      @include ts-no-h1();
    }

    @include media-breakpoint-down(md) {
      max-width: 355px;
      font-size: 65px;
      line-height: 52px;

      @at-root .norwegian-site #{&} {
        font-size: 65px;
        line-height: 58px;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 280px;
      font-size: 52px;
      line-height: 42px;

      @at-root .norwegian-site #{&} {
        font-size: 52px;
        line-height: 46px;
      }
    }
  }

  &.contact .header {
    @include media-breakpoint-up(lg) {
      max-width: 950px;
    }
  }

  &.landingpage .header {
    @include media-breakpoint-up(lg) {
      @include ts-headline-landing();

      @at-root .norwegian-site #{&} {
        @include ts-no-headline-landing();
      }
    }
  }

  &.ar-landing .header {
    text-transform: uppercase;
    font-weight: 900;

    @include media-breakpoint-up(sm) {
    }

    @include media-breakpoint-up(md) {
      max-width: 660px;
      font-size: 102px;
      letter-spacing: -1px;
      line-height: 83px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 760px;
      font-size: 135px;
      letter-spacing: -3.41px;
      line-height: 110px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 850px;
      font-size: 140px;
      letter-spacing: -3.41px;
      line-height: 120px;
    }
  }

  &.crisis {
    background: inherit;
  }

  &.crisis .summary {
    margin-top: 15px;
    font-size: 15px;

    p:not(.preamble) {
      margin-bottom: 0px;
    }

    ul {
      padding-inline-start: 20px;
      font-size: 17px;
    }
  }

  &.crisis ul.list-btn {
    list-style: none;
    padding-inline-start: 0px;
    margin: 30px 0 0 0;

    li {
      padding: 0px;
      margin: 0 0 20px 0;
    }
  }

  &.crisis ul.list-btn .btn {
    width: 300px;

    &::before {
      background: none;
      display: none;
    }

    &.btn-outline-light-download {
      border-color: #fff;
      background-color: transparent;
      color: #fff;

      &::before {
        position: relative;
        display: inline-block;
        content: "";
        width: 13px;
        height: 16px;
        margin-right: 5px;
        bottom: -3px;
        vertical-align: top;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
      }

      &:hover,
      &:active,
      &.active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      &:focus {
        border-color: #1dbfcd;
        background-color: transparent;
        color: #1dbfcd;
        outline: 0;
        box-shadow: none;

        &::before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }

  .preamble {
    max-width: 565px;
    margin-top: 10px;
    @include ts-22-30();
    color: #fff;

    @at-root .dark#{&} {
      color: #000;
    }

    @include media-breakpoint-down(xs) {
      @at-root .person#{&} {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      max-width: 355px;
      font-size: 17px;
      line-height: 25px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 280px;
      font-size: 17px;
      line-height: 25px;
    }

    @include media-breakpoint-up(lg) {
      @at-root .person#{&} {
        max-width: 440px;
      }
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 10px;
    }

    img {
      display: inline-block;
      vertical-align: baseline;
    }
  }

  .name {
    max-width: 565px;
    margin-top: 8px;
    @include ts-h4();

    @include media-breakpoint-down(md) {
      max-width: 355px;
      font-size: 17px;
      line-height: 25px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 280px;
      font-size: 17px;
      line-height: 25px;
    }
  }

  .position {
    max-width: 565px;
    @include ts-22-30();

    @include media-breakpoint-down(md) {
      max-width: 355px;
      font-size: 17px;
      line-height: 25px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 280px;
      font-size: 17px;
      line-height: 25px;
    }
  }

  .icons {
    a:hover svg .bg {
      fill: #47bcca;
    }
  }
}

.hero.person {
  @include media-breakpoint-down(xs) {
    height: 600px;
    padding-top: 0;
    background-color: #fff;
    background-image: none;

    .bg {
      height: calc(100% - 200px);
      z-index: 1;
    }

    .cnt {
      justify-content: flex-end;
      z-index: 3;

      .btns {
        display: flex;
      }
    }

    &::after {
      position: absolute;
      display: block;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 477px;
      background: transparent
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 58%
        );
      z-index: 2;
    }
  }
}

.prvnxt.person.hero .cnt .btns {
  @include media-breakpoint-down(xs) {
    display: flex;
  }
}
