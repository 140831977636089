/* Text Size Key - 1 */
%ts-headline-landing {
    font-size: 115px;
    font-weight: 900;
    letter-spacing: -3px;
    line-height: 90px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

/*@extend %headline-landing;*/

/* Text Size Key - 2 */
%ts-h1 {
    font-size: 95px;
    font-weight: 900;
    letter-spacing: -2px;
    line-height: 73px;
}

/* Text Size Key - 2 - Norwegian */
%ts-no-h1 {
    font-size: 80px;
    line-height: 92px;
}

/* Text Size Key - 3 */
%ts-h2 {
    font-size: 70px;
    font-weight: 900;
    letter-spacing: -1.5px;
    line-height: 56px;
}



/* Text Size Key - 5 */
%ts-h3 {
    font-size: 50px;
    font-weight: 900;
    letter-spacing: -1px;
    line-height: 40px;
}

/* Text Size Key - Norwegian h3 */
%ts-no-h3 {
    line-height: 50px;
}

/* Text Size Key - 4 */
%ts-big-number {
    font-size: 70px;
    font-weight: 300;
    letter-spacing: -1.47px;
    line-height: 92px;
}

/* Text Size Key - 6 */
%ts-medium-number {
    font-size: 50px;
    font-weight: 300;
    letter-spacing: -1.06px;
    line-height: 50px;
}

/* Text Size Key - 7 */
%ts-small-slider-style {
    font-size: 42px;
    font-weight: 900;
    letter-spacing: -0.5px;
    line-height: 35px;
}

/* Text Size Key - 8 */
%ts-main-navigation {
    font-size: 36px;
    font-weight: 900;
    letter-spacing: -0.43px;
    line-height: 35px;
}

/* Text Size Key - 9 */
%ts-h4 {
    font-size: 26px;
    font-weight: 900;
    line-height: 22px;
}

/* Text Size Key - 10 */
%ts-22-30 {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
}

/* Text Size Key - 10A */
%ts-22-30-heading-a {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
}

/* Text Size Key - 10B */
%ts-22-30-heading-b {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
}

/* Text Size Key - 11 */
%ts-17-25 {
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
}

/* Text Size Key - 11A*/
%ts-17-25-heading-a {
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
}

/* Text Size Key - 12*/
%ts-15-20 {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
}

/* Text Size Key - 12A*/
%ts-med {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

/* Text Size Key - 12B*/
%ts-button {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

/* Text Size Key - 12C*/
%ts-breadcrumb {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
}

/* Text Size Key - 12D*/
%ts-descriptorle {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

/* Text Size Key - 12E*/
%ts-15-25-social-body {
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
}

/* Text Size Key - 0 */
%ts-11-eng-nor-top {
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 1.5px;
    line-height: 15px;
}



h3.big {
    @extend %ts-h3;
}

h3.narrow {
    max-width: 300px;
}

h3.small {
    @extend %ts-descriptorle;
}

h3.accent-under,
h4.accent-under {
    padding-bottom: 8px;
}

h3.turquoise {
    color: #1dbfcd;
}

h3.xl {
    font-weight: 300;
    font-size: 161px;
    line-height: 1;
    letter-spacing: -8px;

    @media (max-width: 1343px) {
        font-size: 144px;
    }

    @media (max-width: 1199px) {
        font-size: 124px;
    }
}

.accent-under {
    position: relative;

    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 14px;
        height: 3px;
        background-color: #1dbfcd;
    }
}

.accent-above {
    position: relative;

    &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 3px;
        background-color: #1dbfcd;
    }
}









/*
 *
 * MIXINS
 *
*/



@mixin header-accent($placement: below) {
    position: relative;

    &::after {
        display: block;
        content: '';
        position: absolute;

        @if $placement==below {
            bottom: 0;
        }

        @else {
            top: 0;
        }

        left: 0;
        width: 14px;
        height: 3px;
        background-color: #1dbfcd;
    }
}

/*
@mixin standard-h3($accent: below) {
    @extend %ts-h3;
    text-transform: uppercase;
    @if $placement == below or $placement == above {
        @include header-accent($placement: $accent);
    }
}

@mixin standard-h4 {
    @extend %ts-h4;
    text-transform: uppercase;

    @if $placement == below or $placement == above {
        @include header-accent($placement: $accent);
    }
}
*/

/* Text Size Key - 1 */
@mixin ts-headline-landing() {
    font-size: 115px;
    font-weight: 900;
    letter-spacing: -3px;
    line-height: 90px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
}

@mixin ts-no-headline-landing() {
    font-size: 90px;
    font-weight: 900;
    letter-spacing: -3px;
    line-height: 83px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
}

/* Text Size Key - 2 */
@mixin ts-h1() {
    font-size: 95px;
    font-weight: 900;
    letter-spacing: -2px;
    line-height: 73px;
}

/* Text Size Key - norwegian - 2 */
@mixin ts-no-h1 {
    font-size: 87px;
    line-height: 92px;
}

/* Text Size Key - 3 */
@mixin ts-h2() {
    font-size: 70px;
    font-weight: 900;
    letter-spacing: -1.5px;
    line-height: 56px;
    text-transform: uppercase;
}

/* Text Size Key - 5 */
@mixin ts-h3() {
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 900;
    letter-spacing: -1px;
    line-height: 40px;
}

/* Text Size Key - 5 */
@mixin ts-no-h3() {
    line-height: 50px;
}


/* Text Size Key - 4 */
@mixin ts-big-number() {
    font-size: 70px;
    font-weight: 300;
    letter-spacing: -1.47px;
    line-height: 92px;
}

/* Text Size Key - 6 */
@mixin ts-medium-number() {
    font-size: 50px;
    font-weight: 300;
    letter-spacing: -1.06px;
    line-height: 50px;
}

/* Text Size Key - 7 */
@mixin ts-small-slider-style() {
    font-size: 42px;
    font-weight: 900;
    letter-spacing: -0.5px;
    line-height: 35px;
}

/* Text Size Key - 8 */
@mixin ts-main-navigation() {
    font-size: 36px;
    font-weight: 900;
    letter-spacing: -0.43px;
    line-height: 35px;
}

/* Text Size Key - 9 */
@mixin ts-h4() {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 900;
    line-height: 22px;
}

/* Text Size Key - 10 */
@mixin ts-22-30() {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
}

/* Text Size Key - 10A */
@mixin ts-22-30-heading-a() {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
}

/* Text Size Key - 10B */
@mixin ts-22-30-heading-b() {
    @include ts-22-30();
}

/* Text Size Key - 11 */
@mixin ts-17-25() {
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
}

/* Text Size Key - 11A*/
@mixin ts-17-25-heading-a() {
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
}

/* Text Size Key - 12*/
@mixin ts-15-20() {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
}

/* Text Size Key - 12A*/
@mixin ts-med() {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

/* Text Size Key - 12B*/
@mixin ts-button() {
    text-transform: uppercase;
    @include ts-med();
}

/* Text Size Key - 12C*/
@mixin ts-breadcrumb() {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
}

/* Text Size Key - 12D*/
@mixin ts-descriptorle() {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

/* Text Size Key - 12E*/
@mixin ts-15-25-social-body() {
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
}

/* Text Size Key - 0 */
@mixin ts-11-eng-nor-top() {
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 1.5px;
    line-height: 15px;
}


.header h3 {
    @at-root .norwegian-site #{&} {
        @include ts-no-h3();
    }
}

.pr-item .header h3 {
    @at-root .norwegian-site #{&} {
        @include ts-no-h3();
    }
}

h3 {
    @include ts-h3();

    &.orange {
        color: #ff9a00;
    }

    &.blue {
        color: #0559ac;
    }

    &.turquoise {
        color: #1dbfcd;
    }

    &.purple {
        color: #7b4ba4;
    }

    a:hover {
        text-decoration: none;
    }
}

h4 {
    @include ts-h4();

    &.orange {
        color: #ff9a00;
    }

    &.blue {
        color: #0559ac;
    }

    &.turquoise {
        color: #1dbfcd;
    }

    &.purple {
        color: #7b4ba4;
    }

    a:hover {
        text-decoration: none;
    }



}

main .timeline-wrapper p a {
    text-decoration: underline;
    font-size: inherit;
    font-weight: 300;
    line-height: inherit;
    display: inline;
}

a.bloclink,
.richtext a.bloclink {
    text-decoration: none;
}

.timeline-wrapper a,
.timeline-wrapper .richtext a {
    text-decoration: none;
}

.timeline-wrapper .richtext p a {
    text-decoration: underline;
}

main .timeline-wrapper a,
a.bloclink {
    display: block;
    @include ts-med();
    color: #000;

    &:hover {
        color: #1dbfcd;
        text-decoration: none;
    }

    &.ico-external {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        padding-left: 20px;
        text-decoration: none;
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &.ico-internal {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        padding-left: 20px;
        text-decoration: none;
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &.ico-download {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 0.2em;
        padding-left: 20px;
        text-decoration: none;
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}




main .timeline-wrapper .bi-block.dark a {
    color: #fff;

    &:hover {
        color: #1dbfcd;
        text-decoration: none;
    }

    &.ico-external {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        padding-left: 20px;
        text-decoration: none;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &.ico-internal {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        padding-left: 20px;
        text-decoration: none;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &.ico-download {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 0.2em;
        padding-left: 20px;

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
        }
    }
}