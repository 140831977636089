body.body-background-grey {
    background-color: #F3F3F3;
}

.form-horizontal {
    .form-group>.col-md-12 {
        position: relative;
    }

    textarea {
        width: 100%;
        min-height: 300px;
        border: 0;
        outline: none;
        font-weight: 300;
    }

    #letters-left {
        font-weight: 300;
        font-size: 11px;
        line-height: 20px;
        text-transform: lowercase;
        color: #979797;
    }

    .form-control {
        border: 1px solid #F3F3F3;
        border-radius: 0;
        font-weight: 300;

    }

    .form-control:focus {
        border-bottom: 6px solid #47BCCA;
        outline: none;
        box-shadow: none;
        border-top: none;
        border-right: none;
        border-left: none;
    }

    .btn {
        background-color: #fff;
        outline: none;
    }

    .btn:hover {
        text-decoration: none;
        border: 1px solid #000;
    }

    label.control-label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #979797;
        line-height: 20px;
    }

    .checkmark-v {
        transform: scaleX(-1) rotate(-35deg) translateY(-50%);
        color: #47BCCA;
        display: inline-block;
        position: absolute;
        top: 37%;
        display: none;
        right: 1.5rem;
        font-size: 20px;
        font-weight: 300;
    }
}


// link Card

.link_card_component {
    background-color: #f3f3f3;
    border: 1.2rem solid #f3f3f3;
}

.tag_Link_Card {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    position: relative;
    padding-bottom: 10px;
}

.tag_Link_Card::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 14px;
    height: 3px;
    background-color: #1dbfcd;
}

.heading_Link_Card {
    font-size: 26px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: 900;
}