.bh-light-nav {
    display: inline-flex;
    flex-wrap: nowrap;
    position: relative;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    pointer-events: all;
    z-index: 9;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        transform: translateY(-50%);
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        position: absolute;
        top: -32px;
        left: 50%;
        transform: translateX(-50%);
    }

    margin: 0 auto;

    li {
        flex: 1 1 233px;

        @include media-breakpoint-down(sm) {
            flex: 1 1 auto;
        }

        min-width: 233px;
        background-color: #fff;
        border: 0;
        border-bottom: 10px solid transparent;
        border-left: 0;

        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid #e5e5e5 !important;
            border-left: 10px solid transparent;
        }

        &.active {
            border-bottom-color: #47BCCA;
            border-left-color: #47BCCA;
        }

        &.orange {
            border-bottom-color: #ff9a00;
            border-left-color: #ff9a00;

            @at-root .active-inside#{&}:not(.active) {
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }

        &.blue {
            border-bottom-color: #0559ac;
            border-left-color: #0559ac;

            @at-root .active-inside#{&}:not(.active) {
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }

        &.turquoise {
            border-bottom-color: #1dbfcd;
            border-left-color: #1dbfcd;

            @at-root .active-inside#{&}:not(.active) {
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }

        &.purple {
            border-bottom-color: #7b4ba4;
            border-left-color: #7b4ba4;

            @at-root .active-inside#{&}:not(.active) {
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }

        &.heading {
            display: block;
            background-color: #000;
            border-bottom: 0;
            border-left: 0;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &:not(.heading) {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }

    &.opened li:not(.heading) {
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    a {
        display: block;
        padding: 22px 10px;
        color: #000;
        @include ts-med();
        text-transform: uppercase;
        text-align: center;
    }

    a:hover {
        text-decoration: none;
        color: #47BCCA;

        svg path {
            fill: #47BCCA;
        }
    }

    a:focus {
        outline: none;
    }

    .heading a {
        color: #fff;

        &::before {
            content: '+';
            display: inline-block;
            position: relative;
            margin-right: 10px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            overflow: hidden;
            vertical-align: bottom;
            width: 8px;
            height: 20px;
        }

        svg path {
            fill: #fff;
        }
    }

    &.opened .heading a::before {
        content: '-';
        font-size: 18px;
        line-height: 20px;
    }
    
    .heading a:hover {
        color: #47BCCA;

        svg path {
            fill: #47BCCA;
        }
    }
    
    li.ico-arrow,
    li.ico-chat {
        a::before {
            position: relative;
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: 100% 50%;
            vertical-align: middle;
        }
    }

    li.ico-arrow a::before,
    li.ico-chat a::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/svg%3E");
    }
}
