.timeline-wrapper {
    position: relative;
    margin-bottom: 100px !important;
}


.timeline-wrapper div,
.timeline-wrapper img,
.timeline-wrapper a {
    transition: transform 0.8s cubic-bezier(0, 1, .5, 1), opacity 1s;
}

.timeline-track {
    pointer-events: none;
    display: block;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    width: 100%;

    @media (min-width: 1024px) {
        max-width: 1000px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1344px) {
        max-width: 1320px;
    }

    padding: 0 30px;
    margin: 0 auto;
    position: relative;
    background-color: rgba(255, 0, 0, 0.5);

    .track-col {
        position: absolute;
        top: 250px;
        left: 0;
        max-width: 9.090909%;

        .date-0 {
            .line {
                height: 1100px !important;
            }
        }

        .date-1 {
            .line {
                height: 680px !important;
            }
        }

        .date-2 {
            .line {
                height: 890px !important;
            }
        }

        .date-3 {
            .line {
                height: 680px !important;
            }
        }

        .date-4 {
            .line {
                height: 700px !important;
            }
        }

        .date-5 {
            .line {
                height: 700px !important;
            }
        }

        .date-6 {
            .line {
                height: 910px !important;
            }
        }

        .date-7 {
            .line {
                height: 760px !important;
            }
        }

        .date-8 {
            .line {
                height: 490px !important;
            }
        }

        .date-10 .line {
            height: 876px !important;
        }
    }

    .date-block {

        .date {
            display: block;
            position: relative;
            padding-bottom: 22px;
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
            text-align: right;
            color: #000;

            &.rev {
                color: #fff;
            }

            &::before {
                content: '';
                position: absolute;
                top: 28px;
                right: 0;
                display: block;
                width: 14px;
                height: 4px;
                background-color: #47BCCA;
            }
        }


        .line {
            display: block;
            width: 3px;
            height: 100px;
            margin-left: auto;
            margin-right: 1px;
            background-color: #ddd;
        }
    }
}

.timeline-date {
    display: none;
    position: relative;
    padding-bottom: 22px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
        display: block;
    }

    &::before {
        content: '';
        position: absolute;
        top: 28px;
        left: 0;
        display: block;
        width: 14px;
        height: 4px;
        background-color: #47BCCA;
    }
}

.timeline-wrapper .container {
    @media (max-width: 768px) {
        padding-right: 30px;
        padding-left: 30px;
    }

    .richtext {
        h3 {
            @media (max-width: 768px) {
                font-size: 40px;
                line-height: 30px;
            }
        }
    }

    .header {
        @media (max-width: 768px) {
            font-size: 40px;
            line-height: 30px;
        }
    }
}


@include media-breakpoint-up(md) {


    .timeline-block-4 .richtext,
    .timeline-block-9 .richtext {
        position: relative;
        /*top: 70px;*/
    }

    .timeline-wrapper .richtext {
        z-index: 999;
        position: relative;
        padding-right: 10px;
    }

    .vptest {
        opacity: 0;
        transition: transform 0.8s cubic-bezier(0, 1, .5, 1), opacity 1s;
    }

    .vptest.inview {
        opacity: 1;
    }

    .timeline-wrapper .block-mosaic {
        /*padding-bottom: 60px;*/

        .img-1 {
            width: 494px;
            height: 265px;
        }

        .img-2 {
            width: 221px;
            height: 118px;
        }

        .img-3 {
            width: 221px;
            height: 118px;
        }
    }

    .timeline-block-4 .imgcard,
    .timeline-block-9 .imgcard {
        width: 440px;
        height: 440px;
    }

    .timeline-block-5 a.video.screen.modal,
    .timeline-block-6 a.video.screen.modal,
    .timeline-block-8 a.video.screen.modal {
        width: 350px;
        height: 245px;
    }
}

@include media-breakpoint-up(lg) {

    .timeline-wrapper .block-mosaic {

        /*padding-bottom: 60px;*/
        .img-1 {
            width: 564px;
            height: 302px;
        }

        .img-2 {
            width: 253px;
            height: 135px;
        }

        .img-3 {
            width: 253px;
            height: 135px;
        }
    }

    .timeline-block-4 .imgcard,
    .timeline-block-9 .imgcard {
        width: 504px;
        height: 440px;
    }

    .timeline-block-5 a.video.screen.modal,
    .timeline-block-6 a.video.screen.modal,
    .timeline-block-8 a.video.screen.modal {
        width: 400px;
        height: 281px;
    }

    .timeline-block-2,
    .timeline-block-5,
    .timeline-block-8 {
        min-height: 676px;
        margin-top: 100px !important;
        margin-bottom: 100px !important;

        .bg-img img {
            height: 1100px;
        }
    }

    .timeline-block-3,
    .timeline-block-6,
    .timeline-block-9 {
        margin-bottom: 100px !important;
    }

    .timeline-block-6 .richtext,
    .timeline-block-7 .richtext {
        margin-bottom: 40px !important;
    }

    //Adjustments to match position mid-screen
    .timeline-wrapper .block-mosaic .img-3 img {
        position: relative;
        //top: -34px;
        top: 0;
    }
}

@include media-breakpoint-up(xl) {


    .timeline-wrapper .block-mosaic {

        /*padding-bottom: 60px;*/
        .img-1 {
            width: 655px;
            height: 351px;
        }

        .img-2 {
            width: 293px;
            height: 157px;
        }

        .img-3 {
            width: 293px;
            height: 157px;
        }
    }

    .timeline-block-4 .imgcard,
    .timeline-block-9 .imgcard {
        width: 586px;
        height: 440px;
    }

    .timeline-block-5 a.video.screen.modal,
    .timeline-block-6 a.video.screen.modal,
    .timeline-block-8 a.video.screen.modal {
        width: 466px;
        height: 327px;
    }

    .timeline-block-2,
    .timeline-block-5,
    .timeline-block-8 {
        min-height: 676px;
        margin-top: 100px !important;
        margin-bottom: 100px !important;

        .bg-img img {
            height: 1100px;
        }
    }

    .timeline-block-3,
    .timeline-block-6,
    .timeline-block-9 {
        margin-bottom: 100px !important;
    }

    .timeline-block-6 .richtext,
    .timeline-block-7 .richtext {
        margin-bottom: 40px !important;
    }

    /*
    .timeline-wrapper .timeline-block-1 .block-mosaic .img-2 {
        bottom: 92px;
        position: relative;
    }

    .timeline-wrapper .timeline-block-1 .block-mosaic .img-3 {
        position: relative;
        top: -73px;
    }

    .timeline-wrapper .timeline-block-7 .block-mosaic .img-2 {
        bottom: 322px;
        position: relative;
    }

    .timeline-wrapper .timeline-block-7 .block-mosaic .img-3 {
        position: relative;
        top: -164px;
    }

    .timeline-block-6 .richtext {
        bottom: 117px;
    }

    .timeline-block-7 .richtext {
        bottom: 200px;
    }

    .timeline-block-9 .richtext {
        top: -175px;
        position: relative;
    }


    .timeline-block-10 .richtext {
        top: -230px;
	    position: relative;
    }*/
}

@media (min-width: 1024px) {

    .timeline-block-6,
    .timeline-block-4,
    .timeline-block-9,
    .timeline-block-11 {
        .offset-md-3 {
            margin-left: 18.272727%;
        }
    }

    .timeline-block-9,
    .timeline-block-4 {
        .offset-md-3 {
            margin-right: 9%;
        }
    }
}