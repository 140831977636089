.personcard {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 50%;
    min-height: 250px;

    @include media-breakpoint-up(sm) {
        min-height: 250px;
    }

    @include media-breakpoint-up(md) {
        min-height: 288px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 339px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 380px;
    }

    &::before {
        content: "";
        float: left;
        padding-bottom: 87%;
    }

    &::after {
        clear: left;
        content: " ";
        display: table;
    }


    .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        object-fit: cover;
        height: 690px;
        width: 100%;
        object-position: 50% 100%;
    }

    .copy {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px;
        height: auto;

        @include media-breakpoint-down(sm) {
            bottom: auto;
            top: 0;
        }

        @media (max-width: 380px) {
            padding: 30px 30px 30px 30px;
            max-width: 175px;
        }

        .position {
            position: relative;
            color: #000;
            @include ts-17-25();
            padding-bottom: 10px;
            max-width: 175px;


            @media (max-width: 380px) {
                font-size: 17px;
                line-height: 25px;
            }
        }

        .name {
            padding-bottom: 13px;
            margin-bottom: 2px;
            color: #000;
            @include ts-h3();
            @include header-accent($placement: below);

            @media (max-width: 380px) {
                font-size: 40px;
                line-height: 33px;
            }
        }

        .icons {
            padding-bottom: 60px;
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35'%3E%3Ccircle fill='none' cx='16.307' cy='16.307' r='16.307' stroke='%23000' opacity='.8' /%3E%3Cg fill='%23000'%3E%3Cpath d='M21.334 15.71v.535H10.62v-.536z' /%3E%3Cpath d='M15.71 10.62h.535v10.714h-.536z' /%3E%3C/g%3E%3C/svg%3E") no-repeat 0 100%;

            svg {
                display: block;
            }

            svg + svg {
                margin-top: 10px;
            }

            .plus {
                margin-top: 25px;
            }
        }
    }
}

a.personcard:hover .copy .name {
    color: #1dbfcd;
}

a.personcard:hover .icons {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35'%3E%3Ccircle fill='none' cx='16.307' cy='16.307' r='16.307' stroke='%2347BCCA' opacity='.8' /%3E%3Cg fill='%2347BCCA'%3E%3Cpath d='M21.334 15.71v.535H10.62v-.536z' /%3E%3Cpath d='M15.71 10.62h.535v10.714h-.536z' /%3E%3C/g%3E%3C/svg%3E");
}
