.article-from {
    display: block;
    margin-bottom: 20px;

    .descriptor {
        @include ts-med();
        padding-left: 16px;
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
        margin-bottom: 5px;
    }

    .title {
        @include ts-22-30();

        @include media-breakpoint-up(sm) {
            max-width: 244px;
        }
    }

    &:hover {
        text-decoration: none;

        .descriptor {
            color: #000;
        }
    }
}
