.block-large-image {
	align-items: flex-end!important;

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	.caption {
		.heading {
			@include ts-med();
		}

		.description {
			@include ts-15-20();
		}

		.links {
			margin-top: 20px;

			a {
				display: block;
				@include ts-med();
				color: #000;

				&:hover {
					color: #1dbfcd;
                    text-decoration: none;
				}

				&.ico-external {
					background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
						padding-left: 20px;

					&:hover {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
					}
				}

				&.ico-internal {
					background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
						padding-left: 20px;

					&:hover {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
					}
				}
			}
		}
	}
}
