.block-mosaic {
    align-items: flex-end !important;


    .mosaic {
        display: grid;
        grid-template-columns: 38% 31% 31%;
        grid-template-rows: auto auto;
        grid-template-areas: "primary primary secondary" ". tertiary .";
        grid-column-gap: 2px;
        grid-row-gap: 2px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }

        .img-1 {
            grid-area: primary;
        }

        .img-2 {
            grid-area: secondary;
            align-self: end;
        }

        .img-3 {
            grid-area: tertiary;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }


    &.reverse .mosaic {
        grid-template-columns: 31% 31% 38%;
        grid-template-rows: auto auto;
        grid-template-areas: "secondary primary primary" ". tertiary .";
    }


    .caption {
        h4 {
            padding-bottom: 14px;
            @include ts-h4();
            text-transform: uppercase;
        }

        .description {
            @include ts-22-30();
        }

        a:not(.btn) {
            display: block;
            @include ts-med();
            color: #000;

            &:hover {
                color: #1dbfcd;
                text-decoration: none;
            }

            &.ico-external {
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                padding-left: 20px;

                &:hover {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
                }
            }

            &.ico-internal {
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                padding-left: 20px;

                &:hover {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
    }
}
