
.operations-country-top {
    position: relative;


    .operations-map {
        position: relative;
        width: calc(100% - 412px);

        @at-root .fullwidth#{&} {
            width: 100%;
        }

        min-height: 760px;
        height: 100vh;
        z-index: 1;
        display: flex;
        justify-content: center;
        transition: height 0.3s;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }


        .stats {
            position: absolute;
            bottom: 85px;
            right: 0;
            z-index: 3;
            width: 100%;
            max-width: 503px;
            right: auto;
            display: flex;
            flex-direction: row;

            @include media-breakpoint-down(xs) {
                width: 100%;
                max-width: 100%;
                padding-left: 30px;
                padding-right: 30px;
            }

            .bignumber {
                @include bignumber();
                color: #fff;
                flex: 1 1 auto;

                .number {
                    font-size: 47px;
                    line-height: 50px;
                }
            }
        }

        .cnt {
            position: relative;
            max-width: 100%;
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            z-index: 3;
            pointer-events: none;
            align-items: flex-start;
            padding-bottom: 130px;
            padding-top: 130px;

            @include media-breakpoint-down(sm) {
                padding-top: 125px;
                align-items: flex-start;
            }

            padding-left: 30px;
            padding-right: 30px;

            a {
                pointer-events: all;
            }

            .copy {
                .descriptor {
                    position: relative;
                    padding-bottom: 10px;
                    margin-bottom: 6px;
                    color: #fff;
                    @include ts-descriptorle();
                    font-weight: 700;
                    text-transform: uppercase;

                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 14px;
                        height: 3px;
                        background-color: #1dbfcd;
                    }

                    .thin {
                        font-weight: 300;
                    }

                    a {
                        color: #fff;
                        pointer-events: all;

                        &:hover {
                            color: #47BCCA;
                            text-decoration: none;
                        }
                    }
                }

                .header {
                    max-width: 503px;
                    color: #fff;
                    @include ts-h1();

                    @at-root .norwegian-site #{&} {
                        @include ts-no-h1();
                    }

                    text-transform: uppercase;

                    @media (max-width: 400px) {
                        max-width: 90%;
                    }

                    @include media-breakpoint-down(sm) {
                        @include ts-h3();

                        @at-root .norwegian-site #{&} {
                            @include ts-no-h3();
                        }
                    }

                    .turquoise {
                        color: #1dbfcd;
                    }
                }

                .preamble {
                    max-width: 363px;
                    margin-top: 20px;

                    @include ts-17-25();
                    color: #fff;

                    @at-root .dark#{&} {
                        color: #000;
                    }

                    & > *:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 10px;
                    }

                    a.btn {
                        margin-top: 30px;
                    }
                }
            }
        }

        #map {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            .mapboxgl-popup {
                font-family: "Flama", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

                h3 {
                    @include ts-22-30();
                }

                h4 {
                }
            }
        }
    }

    .feats {
        width: 412px;
        position: absolute;
        top: 111px;
        right: 0;
        bottom: 0;
        display: flex;

        @at-root .fullwidth#{&} {
            display: none;
        }

        flex-direction: column;

        @include media-breakpoint-down(sm) {
            width: 100%;
            position: relative;
            top: auto;
            flex-direction: row;
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        .item {
            @extend .imgcard;

            @include media-breakpoint-up(md) {
                height: 100%;
            }

            .copy {
                width: 100%;
            }
        }
    }
}

