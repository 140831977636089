.bigfeat {
    position: relative;
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include media-breakpoint-down(xs) {
        flex-direction: column;
    }

    @include media-breakpoint-up(sm) {
        padding-left: calc((100% - (#{map-get($container-max-widths, 'sm')} - 60px)) / 2);
    }

    @include media-breakpoint-up(md) {
        padding-left: calc((100% - (#{map-get($container-max-widths, 'md')} - 60px)) / 2);
    }

    @include media-breakpoint-up(lg) {
        padding-left: calc((100% - (#{map-get($container-max-widths, 'lg')} - 60px)) / 2);
    }

    @include media-breakpoint-up(xl) {
        padding-left: calc((100% - (#{map-get($container-max-widths, 'xl')} - 60px)) / 2);
    }

    .primary {
        width: 100%;
        padding: 130px 70px 130px 0;
        max-width: 620px;
        z-index: 2;

        @include media-breakpoint-down(xs) {
            max-width: 100%;
            padding: 80px 30px 80px 30px;
        }

        .descriptor {
            @include ts-med();
            color: #fff;

            @at-root .dark#{&} {
                color: #000;
            }

            text-transform: uppercase;
            margin-bottom: 8px;
            padding-bottom: 8px;
            @include header-accent($placement: below);
        }

        h3 {
            @include ts-h2();

            @include media-breakpoint-down(xs) {
                @include ts-h3();
            }

            color: #fff;

            @at-root .dark#{&} {
                color: #000;
            }

            @at-root .ar#{&} {
                @include ts-h3();
            }

            margin-bottom: 30px;
        }

        .richtext {
            @include ts-17-25();
            color: #fff;

            @at-root .dark#{&} {
                color: #000;
            }

            margin-bottom: 30px;
        }
    }

    &.tint-black::before,
    &.tint-white::before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &.tint-black::before {
        background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
    }

    &.tint-white::before {
        background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 100%);
    }

    .related {
        width: 413px;
        margin-left: auto;
        padding: 130px 70px;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        z-index: 2;

        @include media-breakpoint-down(xs) {
            width: 100%;
            padding: 40px 30px 80px 30px;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(0,0,0,1) 100px);
        }

        h4 {
            @include ts-h4();
            @include header-accent($placement: below);
            color: #fff;
            padding-bottom: 8px;
            margin-bottom: 8px;
        }

        .item {
            @include ts-22-30();
            color: #fff;

            & + item {
                margin-top: 20px;
            }

            .link-visual {
                @include ts-med();

                &.ico-external {
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                    padding-left: 20px;
                }

                &.ico-internal {
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                    padding-left: 20px;
                }

                &.ico-download {
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E") no-repeat 0 50%;
                    padding-left: 20px;
                }
            }

            &:hover {
                text-decoration: none;
            }

            &:hover .link-visual {
                color: #1dbfcd;

                &.ico-external {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.11 8.39L8.89.61M3.232.61H8.89v5.658'/%3E%3C/g%3E%3C/svg%3E");
                }

                &.ico-internal {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
                }

                &.ico-download {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231dbfcd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M5 1v11M1 15h8M9 8l-4 4-4-4'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
    }
}
