.cookie-info {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    background-color: rgba(0,0,0,0.9);
    height: auto;
    z-index: 1051;
    color: #fff;
    padding: 60px 30px;

    .col {
        @include media-breakpoint-down(xs) {
            @include make-col(1,1);
        }
    }



    .copy {
        .richtext {
            max-width: 1000px;
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
        }


        a {
            color: #fff;
        }

        a:hover {
        }
    }

    .btns {
        flex: 0 1 233px;
    }

    a.internal {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1 4.5h11M8 8.5l4-4-4-4'/%3E%3C/g%3E%3C/svg%3E");
    }
}
